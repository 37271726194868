import { captureException } from "@sentry/react";

import { t } from "utils/i18n";

type ZxcvbnTKey =
    "Zxcvbn_UseFewWords" |
    "Zxcvbn_NoSymbolsDigits" |
    "Zxcvbn_StarightRows" |
    "Zxcvbn_StraightRows" |
    "Zxcvbn_ShortPatterns" |
    "Zxcvbn_UseLongerPatterns" |
    "Zxcvbn_RepeatsAaa" |
    "Zxcvbn_RepeatsAbcAbc" |
    "Zxcvbn_AvoidRepatedWords" |
    "Zxcvbn_AvoidSequences" |
    "Zxcvbn_RecentYearsAreEasy" |
    "Zxcvbn_AvoidRecentYears" |
    "Zxcvbn_AvoidYears" |
    "Zxcvbn_AvoidDates" |
    "Zxcvbn_DatesAreEasy" |
    "Zxcvbn_Top10" |
    "Zxcvbn_Top100" |
    "Zxcvbn_VeryCommon" |
    "Zxcvbn_ThisIsSimilar" |
    "Zxcvbn_AWordByItself" |
    "Zxcvbn_NamesAndSurnames" |
    "Zxcvbn_CommonNamesAreEasy" |
    "Zxcvbn_Capitalization" |
    "Zxcvbn_UppercaseIsEasy" |
    "Zxcvbn_ReversedWordsAreEasy" |
    "Zxcvbn_SubstitutionsAreEasy";

export function translateFeedback(feedback: Feedback): string {
    let errorStr = t("WeakPassword");
    const warning = feedback.warning;
    const suggestions = feedback.suggestions;

    if (warning && warning.length > 0) {
        errorStr += "; " + t(getI18nKey(warning));

        if (suggestions && suggestions.length > 0) {
            suggestions.forEach((suggestion: string) => {
                errorStr += ", " + t(getI18nKey(suggestion));
            });
        }
    }

    return errorStr + ".";
}

// locize-use-prefix Zxcvbn_
function getI18nKey(feedback: string): ZxcvbnTKey {
    const tKeys = Object.keys(keys) as Array<keyof typeof keys>;
    const key = tKeys.find(key => keys[key] === feedback);
    if (key === undefined) {
        const error = new Error(`Could not find matching zxcvbn key for "${feedback}"`);
        console.error(error);
        captureException(error);
        return "INVALID_ZXCVBN_T_KEY" as ZxcvbnTKey;
    }
    return key;
}

const keys: Record<ZxcvbnTKey, string> = {
    "Zxcvbn_UseFewWords": "Use a few words, avoid common phrases",
    "Zxcvbn_NoSymbolsDigits": "No need for symbols, digits, or uppercase letters",
    "Zxcvbn_StarightRows": "Add another word or two. Uncommon words are better.",
    "Zxcvbn_StraightRows": "Straight rows of keys are easy to guess",
    "Zxcvbn_ShortPatterns": "Short keyboard patterns are easy to guess",
    "Zxcvbn_UseLongerPatterns": "Use a longer keyboard pattern with more turns",
    "Zxcvbn_RepeatsAaa": "Repeats like \"aaa\" are easy to guess",
    "Zxcvbn_RepeatsAbcAbc": "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"",
    "Zxcvbn_AvoidRepatedWords": "Avoid repeated words and characters",
    "Zxcvbn_AvoidSequences": "Avoid sequences",
    "Zxcvbn_RecentYearsAreEasy": "Recent years are easy to guess",
    "Zxcvbn_AvoidRecentYears": "Avoid recent years",
    "Zxcvbn_AvoidYears": "Avoid years that are associated with you",
    "Zxcvbn_AvoidDates": "Avoid dates and years that are associated with you",
    "Zxcvbn_DatesAreEasy": "Dates are often easy to guess",
    "Zxcvbn_Top10": "This is a top-10 common password",
    "Zxcvbn_Top100": "This is a top-100 common password",
    "Zxcvbn_VeryCommon": "This is a very common password",
    "Zxcvbn_ThisIsSimilar": "This is similar to a commonly used password",
    "Zxcvbn_AWordByItself": "A word by itself is easy to guess",
    "Zxcvbn_NamesAndSurnames": "Names and surnames by themselves are easy to guess",
    "Zxcvbn_CommonNamesAreEasy": "Common names and surnames are easy to guess",
    "Zxcvbn_Capitalization": "Capitalization doesn't help very much",
    "Zxcvbn_UppercaseIsEasy": "All-uppercase is almost as easy to guess as all-lowercase",
    "Zxcvbn_ReversedWordsAreEasy": "Reversed words aren't much harder to guess",
    "Zxcvbn_SubstitutionsAreEasy": "Predictable substitutions like '@' instead of 'a' don't help very much",
} as const;

interface Feedback {
    warning: string,
    suggestions: string[],
}
