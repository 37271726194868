import { CustomDetector, DetectorOptions } from "i18next-browser-languagedetector";

import { getOrgProfileCookie } from "./profileCookie";

const detector: CustomDetector = {
    name: "ImperoLangDetector",

    lookup(_options: DetectorOptions) {
        const cookie = getOrgProfileCookie();
        return cookie?.culture || "en";
    },
};

export default detector;
