
import * as React from "react";

import { Progress } from "antd";

import theme from "./theme.module.scss";
class ProgressBar extends React.PureComponent<ProgressBarProps> {
    render = () => {
        const { percent, showInfo } = this.props;

        return (
            <Progress className={theme.progressBar} percent={percent} showInfo={showInfo} />
        );
    };
}

interface ProgressBarProps {
    percent: number,
    showInfo?: boolean,
}

export {
    ProgressBar,
};
export type {
    ProgressBarProps,
};
