import React from "react";

import { QueryClientProvider as TanstackQueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "utils/query_client";

interface QueryClientProviderProps {
    children: React.ReactNode,
}
export const QueryClientProvider = ({
    children,
}: QueryClientProviderProps) => {
    return (
        <TanstackQueryClientProvider client={queryClient}>
            {children}
        </TanstackQueryClientProvider>
    );
};
