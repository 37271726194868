import * as React from "react";

import { OrgCookie } from "utils/orgCookie";
import { Svg } from "utils/svgIcon";

import theme from "./theme.module.scss";

const RightSideIcon = (props: RightSideIconProps) => {
    const orgCookie = props.customImages ? props.orgCookie : undefined;

    const backgroundPath = orgCookie && orgCookie.loginRightSideBackground ?
        "/org-img/" + orgCookie.loginRightSideBackground :
        undefined;

    const backgroundCSS = backgroundPath ?
        'url("' + backgroundPath + '")' :
        undefined;

    const Foreground = () => {
        // Do not fallback on default icon if background image is custom and custom logo is null
        if (orgCookie && !orgCookie.loginRightSideLogo && orgCookie.loginRightSideBackground) {
            return null;
        } else {
            return orgCookie && orgCookie.loginRightSideLogo ?
                <img className={theme.rightSideIcon} src={"/org-img/" + orgCookie.loginRightSideLogo} /> :
                <Svg className={theme.rightSideIcon} src={props.icon} />;
        }
    };

    return (
        <div className={theme.rightSideIconContainer} style={backgroundCSS ? { backgroundImage: backgroundCSS } : undefined}>
            <Foreground />
        </div>);
};

interface CustomRightSideIconProps {
    icon: string,
    customImages: true,
    orgCookie: OrgCookie,
}

interface NonCustomRightSideIconProps {
    icon: string,
    customImages: false,
}

type RightSideIconProps = CustomRightSideIconProps | NonCustomRightSideIconProps;

export default RightSideIcon;
