import * as React from "react";

import theme from "./theme.module.scss";

interface FormSubtitleProps {
    id?: string,
    children?: React.ReactNode,
    subtitle: string,
    styling?: Object,
}

class FormSubtitle extends React.PureComponent<FormSubtitleProps> {
    render = (): JSX.Element => {
        const { id, subtitle, children, styling } = this.props;
        return (
            <div id={id === null ? "form" : id} className={theme.formSubtitle}>
                <h4 style={styling}>{subtitle}</h4>
                {children}
            </div>
        );
    };
}

export { FormSubtitle };
export type { FormSubtitleProps };
