import * as React from "react";

import Dropdown, {
    DropdownTrigger,
    DropdownContent,
} from "react-simple-dropdown";

import { Arrow } from "../../components/arrow";
import { KeyTrap } from "../../components/keyTrap";
import { set, assertNever } from "../../utils/obj";

import theme from "./theme.module.scss";

class ComplexDropdown extends React.PureComponent<
ComplexDropdownProps,
ComplexDropdownState
> {
    private dropdown: Dropdown | null = null;
    private filterInput: HTMLInputElement | null = null;

    constructor(props: ComplexDropdownProps) {
        super(props);
        this.dropdown = null;
        const option = this.buildOptionsFromProps(this.props);
        this.state = {
            option,
            filteredOption: option,
            preselectedOption: null,
            preselectedOptionParentIds: null,
        };
    }
    componentWillMount() {
        const option = this.buildOptionsFromProps(this.props);
        this.setState({
            option,
            filteredOption: option,
            preselectedOption: null,
            preselectedOptionParentIds: null,
        });
    }
    componentWillReceiveProps(nextProps: ComplexDropdownProps) {
        this.filter(nextProps, this.filterInput ? this.filterInput.value : "");
    }
    private buildOptionsFromProps(props: ComplexDropdownProps): ComplexOption {
        const { options, selectedOptionId } = props;
        // Build a "dummy" complex option, as it makes recursion, etc easier
        return {
            type: "ComplexOption",
            getTextLabel: () => "dummy",
            id: "dummy",
            options,
            selectedOptionId,
        };
    }
    render() {
        const { id, disableFilter } = this.props;
        const { option, filteredOption } = this.state;

        const realStyles: ComplexDropdownStyles =
            this.props.styles || DefaultComplexDropdownStyles;

        const classNames = [realStyles.dropdownClassName];
        const hasSuggestions =
            filteredOption.options && filteredOption.options.length > 0;
        if (hasSuggestions) {
            classNames.push(realStyles.withSuggestionsClassName);
        } else {
            classNames.push(realStyles.noSuggestionsClassName);
        }
        return (
            <Dropdown
                ref={(elt: any) => this.dropdown = elt}
                className={classNames.join(" ")}
                onShow={() => {
                    setTimeout(() => {
                        if (this.filterInput) {
                            this.filterInput.focus();
                        }
                    }, 1);
                }}
                onHide={() => {
                    if (this.filterInput) {
                        this.filterInput.value = "";
                    }
                    const option = this.buildOptionsFromProps(this.props);
                    this.setState({
                        option,
                        filteredOption: option,
                        preselectedOption: null,
                        preselectedOptionParentIds: null,
                    });
                }}
            >
                <DropdownTrigger>
                    <KeyTrap onLocalKeyUp={this.onLocalKeyUp()}>
                        <div>
                            <div
                                {...{ id }}
                                className={`${
                                    realStyles.triggerInnerClassName
                                } ${
                                    disableFilter
                                        ? ""
                                        : realStyles.triggerWithFilterClassName
                                }`}
                            >
                                <span
                                    className={realStyles.triggerLabelClassName}
                                >
                                    {this.getSelectedLabel(option)}
                                </span>{" "}
                                <span
                                    className={
                                        realStyles.arrowContainerClassName
                                    }
                                >
                                    <Arrow
                                        className={realStyles.arrowClassName}
                                    />
                                </span>
                            </div>
                            {disableFilter ? null : (
                                <div
                                    className={realStyles.triggerInputClassName}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <input
                                        id={`${id}-input`}
                                        ref={input =>
                                            this.filterInput = input
                                        }
                                        type="text"
                                        defaultValue=""
                                        onChange={e =>
                                            this.filter(
                                                this.props,
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </KeyTrap>
                </DropdownTrigger>
                <DropdownContent>
                    <div
                        className={realStyles.scrollerClassName}
                    >
                        {hasSuggestions
                            ? this.buildComplexSubOptions(
                                filteredOption,
                                1,
                                [],
                                realStyles
                            )
                            : null}
                    </div>
                </DropdownContent>
            </Dropdown>
        );
    }
    private getSelectedLabel(
        option: DropdownOption | undefined
    ): React.ReactNode {
        if (!option) {
            return this.props.label || "";
        }
        switch (option.type) {
            case "SimpleOption":
                if (option.getSelectedLabel) {
                    return option.getSelectedLabel();
                }
                if (option.getLabel) {
                    return option.getLabel();
                }

                return option.getTextLabel();
            case "ComplexOption":
                return option.getSelectedLabel
                    ? option.getSelectedLabel(option.selectedOptionId)
                    : this.getSelectedLabel(
                        option.options?.find(
                            opt => opt.id === option.selectedOptionId
                        ) ?? undefined
                    );
        }
    }
    private buildComplexSubOptions(
        parent: ComplexOption,
        depth: number,
        parentIds: Array<string | number>,
        realStyles: ComplexDropdownStyles
    ): React.ReactNode {
        const { preselectedOption } = this.state;
        return (
            <ul className={`depth_${depth} section_${parent.id}`}>
                {parent.options.map((option, optionIndex) => {
                    const isPreselected =
                        preselectedOption && option.id === preselectedOption.id;
                    const labelClassNames = isPreselected
                        ? [
                            realStyles.optionLabelClassName,
                            realStyles.preselectedOptionClassName,
                        ]
                        : [realStyles.optionLabelClassName];
                    return [
                        depth > 1 || optionIndex > 0 ? (
                            <hr key={option.id + "_sep"} />
                        ) : null,
                        <li
                            key={option.id}
                            className={
                                option.id === parent.selectedOptionId
                                    ? realStyles.selectedOptionClassName
                                    : undefined
                            }
                        >
                            <div
                                className={labelClassNames.join(" ")}
                                onMouseEnter={e => {
                                    e.preventDefault();
                                    this.setState({
                                        preselectedOption: option,
                                        preselectedOptionParentIds: parentIds,
                                    });
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    if (option.type === "SimpleOption") {
                                        this.handleOptionClick(
                                            option,
                                            parentIds
                                        );
                                    }
                                }}
                            >
                                {option.getLabel
                                    ? option.getLabel()
                                    : option.getTextLabel()}
                            </div>
                            {option.type === "ComplexOption"
                                ? this.buildComplexSubOptions(
                                    option,
                                    depth + 1,
                                    parentIds.concat([option.id]),
                                    realStyles
                                )
                                : null}
                        </li>,
                    ];
                })}
            </ul>
        );
    }
    private findDeepestSelectedOption(
        option: DropdownOption,
        parentIds: Array<string | number> | null
    ): [DropdownOption | null, Array<string | number> | null] {
        switch (option.type) {
            case "SimpleOption": {
                return [option, parentIds];
            }
            case "ComplexOption": {
                const subOption = option.options.find(
                    o => o.id === option.selectedOptionId
                );
                if (subOption) {
                    const subParentIds = parentIds
                        ? parentIds.concat([option.id])
                        : []; /*dummy root*/
                    const result = this.findDeepestSelectedOption(
                        subOption,
                        subParentIds
                    );
                    if (result) {
                        return result;
                    } else {
                        return [subOption, subParentIds];
                    }
                } else {
                    return [option, parentIds];
                }
            }
            default:
                return assertNever(option);
        }
    }
    private findFirstSelectableOption(
        root: ComplexOption
    ): [DropdownOption | null, Array<string | number> | null] {
        if (root.options.length > 0) {
            return [root.options[0], []];
        } else {
            return [null, null];
        }
    }
    private findLastSelectableOption(
        root: ComplexOption
    ): [DropdownOption | null, Array<string | number> | null] {
        let newOption: DropdownOption | null = null;
        let newOptionParentIds: Array<string | number> | null = null;
        if (root.options.length > 0) {
            let lastOption: DropdownOption =
                root.options[root.options.length - 1];
            newOptionParentIds = [];
            while (
                lastOption &&
                lastOption.type === "ComplexOption" &&
                lastOption.options &&
                lastOption.options.length > 0
            ) {
                const subOptions = lastOption.options;
                newOptionParentIds.push(lastOption.id);
                lastOption = subOptions[subOptions.length - 1]!;
            }
            newOption = lastOption;
        }
        return [newOption, newOptionParentIds];
    }
    private findNextSelectableOption(
        root: ComplexOption,
        preselectedOption: DropdownOption
    ): [DropdownOption | null, Array<string | number> | null] {
        let newOption: DropdownOption | null = null;
        let newOptionParentIds: Array<string | number> | null = null;
        let match = false;
        const callback: (
            option: DropdownOption,
            parentIds: Array<string | number>
        ) => boolean = (option, parentIds) => {
            if (match) {
                // previous option matched
                newOption = option;
                newOptionParentIds = parentIds;
                return true;
            }
            match = option.id === preselectedOption.id;
            if (option.type === "ComplexOption") {
                return option.options.some(subOption =>
                    callback(subOption, parentIds.concat([option.id]))
                );
            } else {
                return false;
            }
        };
        root.options.some(option => callback(option, []));
        return [newOption, newOptionParentIds];
    }
    private findPreviousSelectableOption(
        root: ComplexOption,
        preselectedOption: DropdownOption
    ): [DropdownOption | null, Array<string | number> | null] {
        let newOption: DropdownOption | null = null;
        let newOptionParentIds: Array<string | number> | null = null;
        const callback: (
            option: DropdownOption,
            parentIds: Array<string | number>
        ) => boolean = (option, parentIds) => {
            if (option.id === preselectedOption.id) {
                return true;
            }
            newOption = option;
            newOptionParentIds = parentIds;
            if (option.type === "ComplexOption") {
                return option.options.some(subOption =>
                    callback(subOption, parentIds.concat([option.id]))
                );
            } else {
                return false;
            }
        };
        if (!root.options.some(option => callback(option, []))) {
            newOption = null;
            newOptionParentIds = null;
        }
        return [newOption, newOptionParentIds];
    }
    private onLocalKeyUp() {
        return {
            down: () => {
                if (!this.dropdown || !this.dropdown.isActive()) {
                    return true;
                }
                const { filteredOption, preselectedOption } = this.state;
                let newPreselectedOption: DropdownOption | null = null;
                let newPreselectedOptionParentIds: Array<
                string | number
                > | null = null;
                if (preselectedOption === null) {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findDeepestSelectedOption(filteredOption, null);
                    if (newPreselectedOption === filteredOption) {
                        newPreselectedOption = null;
                        newPreselectedOptionParentIds = null;
                    }
                } else {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findNextSelectableOption(
                            filteredOption,
                            preselectedOption
                        );
                }
                // If not found, start at the beginning.
                if (!newPreselectedOption) {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findFirstSelectableOption(filteredOption);
                }
                // Update state
                if (newPreselectedOption !== this.state.preselectedOption) {
                    this.setState({
                        preselectedOption: newPreselectedOption,
                        preselectedOptionParentIds:
                            newPreselectedOptionParentIds,
                    });
                }
                return false;
            },
            up: () => {
                if (!this.dropdown || !this.dropdown.isActive()) {
                    return true;
                }
                const { filteredOption, preselectedOption } = this.state;
                let newPreselectedOption: DropdownOption | null = null;
                let newPreselectedOptionParentIds: Array<
                string | number
                > | null = null;
                if (preselectedOption === null) {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findDeepestSelectedOption(filteredOption, null);
                    if (newPreselectedOption === filteredOption) {
                        newPreselectedOption = null;
                        newPreselectedOptionParentIds = null;
                    }
                } else {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findPreviousSelectableOption(
                            filteredOption,
                            preselectedOption
                        );
                }
                // If not found, start at the end.
                if (!newPreselectedOption) {
                    [newPreselectedOption, newPreselectedOptionParentIds] =
                        this.findLastSelectableOption(filteredOption);
                }
                // Update state
                if (newPreselectedOption !== this.state.preselectedOption) {
                    this.setState({
                        preselectedOption: newPreselectedOption,
                        preselectedOptionParentIds:
                            newPreselectedOptionParentIds,
                    });
                }
                return false;
            },
            enter: () => {
                if (!this.dropdown || !this.dropdown.isActive()) {
                    return true;
                }
                if (
                    this.state.preselectedOption &&
                    this.state.preselectedOptionParentIds
                ) {
                    this.handleOptionClick(
                        this.state.preselectedOption,
                        this.state.preselectedOptionParentIds
                    );
                }
                return false;
            },
            esc: () => {
                if (!this.dropdown || !this.dropdown.isActive()) {
                    return true;
                }
                if (this.dropdown !== null) {
                    this.dropdown.hide();
                }
                return false;
            },
        };
    }
    private filter(props: ComplexDropdownProps, text: string) {
        // Reset option
        const option = this.buildOptionsFromProps(props);
        // Filter options
        const filteredOptions =
            text !== undefined && text !== null && text !== ""
                ? this.filterOptions(option.options, text)
                : option.options;
        // Update state
        const filteredOption =
            option.options === filteredOptions
                ? option
                : this.buildOptionsFromProps({
                    id: this.props.id,
                    options: filteredOptions,
                    selectedOptionId: this.props.selectedOptionId,
                });
        this.setState({
            option,
            filteredOption,
            preselectedOption: null,
            preselectedOptionParentIds: null,
        });
    }
    private filterOptions(
        options: Array<DropdownOption>,
        text: string
    ): Array<DropdownOption> {
        const filteredOptions: Array<DropdownOption> = options
            .map(option => {
                const label = option.getTextLabel();
                const labelMatch =
                    label.toLowerCase().indexOf(text.toLowerCase()) >= 0;
                switch (option.type) {
                    case "SimpleOption": {
                        return labelMatch ? option : null;
                    }
                    case "ComplexOption": {
                        const filteredSubOptions = this.filterOptions(
                            option.options,
                            text
                        );
                        if (labelMatch || filteredSubOptions.length > 0) {
                            if (option.options !== filteredSubOptions) {
                                return set(option, {
                                    options: filteredSubOptions,
                                });
                            } else {
                                return option;
                            }
                        } else {
                            return null;
                        }
                    }
                    default:
                        return assertNever(option);
                }
            })
            .filter((option: DropdownOption | null) => option !== null) as Array<DropdownOption>;
        // Shallow compare
        if (options.length !== filteredOptions.length) {
            return filteredOptions;
        }
        for (let i = 0; i < options.length; ++i) {
            if (options[i] !== filteredOptions[i]) {
                return filteredOptions;
            }
        }
        return options;
    }
    private handleOptionClick(
        option: DropdownOption,
        parentIds: Array<string | number>
    ) {
        const newOption = this.buildNewOption(
            this.state.option,
            parentIds,
            option.id
        );
        const newFilteredOption =
            this.state.option === this.state.filteredOption
                ? newOption
                : this.buildNewOption(this.state.option, parentIds, option.id);
        this.setState({
            option: newOption,
            filteredOption: newFilteredOption,
            preselectedOption: this.state.preselectedOption,
            preselectedOptionParentIds: this.state.preselectedOptionParentIds,
        });
        if (option.type === "SimpleOption") {
            if (this.dropdown !== null) {
                this.dropdown.hide();
            }
            option.onSelect();
        }
    }
    private buildNewOption(
        option: ComplexOption,
        parentIds: Array<string | number>,
        optionId: string | number
    ): ComplexOption {
        if (parentIds.length <= 0) {
            return set(option, {
                selectedOptionId: optionId,
            });
        } else {
            return set(option, {
                selectedOptionId: parentIds[0],
                options: option.options.map(subOption => {
                    if (
                        subOption.type === "ComplexOption" &&
                        subOption.id === parentIds[0]
                    ) {
                        return this.buildNewOption(
                            subOption,
                            parentIds.slice(1),
                            optionId
                        );
                    } else {
                        return subOption;
                    }
                }),
            });
        }
    }
}

interface ComplexDropdownStyles {
    dropdownClassName: string,
    withSuggestionsClassName: string,
    noSuggestionsClassName: string,

    triggerInnerClassName: string,
    triggerWithFilterClassName: string,
    triggerLabelClassName: string,
    arrowContainerClassName: string,
    arrowClassName: string,

    triggerInputClassName: string,

    scrollerClassName: string,

    optionLabelClassName: string,
    preselectedOptionClassName: string,
    selectedOptionClassName: string,
}

interface ComplexDropdownProps {
    id: string,
    label?: string,
    styles?: ComplexDropdownStyles,
    selectedOptionId: string | number,
    options: Array<DropdownOption>,
    disableFilter?: boolean,
    children?: React.ReactNode,
}

interface ComplexDropdownState {
    option: ComplexOption,
    filteredOption: ComplexOption,
    preselectedOption: DropdownOption | null,
    preselectedOptionParentIds: Array<string | number> | null,
}

const DefaultComplexDropdownStyles: ComplexDropdownStyles = {
    dropdownClassName: theme.complexDropdown,
    withSuggestionsClassName: theme.withSuggestions,
    noSuggestionsClassName: theme.noSuggestions,

    triggerInnerClassName: theme.complexTriggerInner,
    triggerWithFilterClassName: theme.triggerWithFilter,
    triggerLabelClassName: theme.complexTriggerLabel,
    arrowContainerClassName: theme.complexArrowContainer,
    arrowClassName: theme.complexArrow,

    triggerInputClassName: theme.triggerInput,

    scrollerClassName: theme.scroller,

    optionLabelClassName: `${theme.optionLabel} ${theme.complexOptionLabel}`,
    preselectedOptionClassName: theme.preselectedOption,
    selectedOptionClassName: theme.selectedOption,
};

const FullWidthComplexDropdownStyles: ComplexDropdownStyles = set(
    DefaultComplexDropdownStyles,
    {
        dropdownClassName: theme.fullWidthComplexDropdown,
        triggerInnerClassName: theme.fullWidthComplexTriggerInner,
    }
);

interface BaseOption {
    id: string | number,
    getLabel?: () => React.ReactNode,
    getTextLabel: () => string,
}

interface SimpleOption extends BaseOption {
    type: "SimpleOption",
    onSelect: () => void,
    getSelectedLabel?: () => React.ReactNode,
}

interface ComplexOption extends BaseOption {
    type: "ComplexOption",
    selectedOptionId: string | number,
    getSelectedLabel?: (selectedOptionId: string | number) => React.ReactNode,
    options: Array<DropdownOption>,
}

type DropdownOption = SimpleOption | ComplexOption;

export {
    ComplexDropdown,
    DefaultComplexDropdownStyles,
    FullWidthComplexDropdownStyles,
};
export type {
    ComplexDropdownProps,
    ComplexDropdownStyles,
    SimpleOption,
    ComplexOption,
    DropdownOption,
};
