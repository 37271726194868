import * as React from "react";

import AntdForm from "@ant-design/compatible/es/form";
import * as Antd from "@ant-design/compatible/es/form/Form";
import { once } from "lodash-es";

import { t } from "utils/i18n";

import { ChangeError, EditFormProps, EditProps, ValueLock } from "../components/form/legacyFields";

import { assertNever } from "./obj";

interface Field<T> {
    value: T,
    errors?: ChangeError[],
    validating?: boolean,
}

/**
 * Extension of Antd's ValidationRule
 *
 * It's unsafe because `validator` & `asyncValidator` would erase other rules defined in the same object,
 * use `ValidationRule` instead
 */
interface UnsafeValidationRule extends Antd.ValidationRule {
    /**
     * Name of the field in error messages, supported by the patched version of async-validator
     */
    displayField?: string,
    /**
     * Expose promise-based validator provided by async-validator, which isn't exposed by antd for reasons ¯\_(ツ)_/¯
     */
    asyncValidator?: (rule: any, value: any) => Promise<void>,
}

interface CustomizedValidation {
    validateStatus?: "success" | "warning" | "error" | "validating",
    hasFeedback?: boolean,
    help?: string,
}

interface ResolvedRule {
    displayField?: string,
    field: string,
    fullField: string,
    type: string,
}

type CustomValidatorFunction<T> = (value: T, rule: ResolvedRule) => string | undefined;
type AsyncValidatorFunction<T> = (value: T, rule: ResolvedRule) => Promise<void>;

type ValidationRule<T> = ({ kind: "ruleset" } & Omit<UnsafeValidationRule, "validator" | "asyncValidator">)
| ({ kind: "custom", validator: CustomValidatorFunction<T> })
| ({ kind: "async", asyncValidator: AsyncValidatorFunction<T> });

function validationRulesConverter<T>() {
    return function <ID extends keyof DATA & string, DATA extends { [K in ID]: T }> (id: ID, form: WrappedFormUtils<DATA, unknown>, validationRules: ValidationRule<T>[] | undefined, displayField?: string): UnsafeValidationRule[] | undefined {
        return intoUnsafeValidationRules(validationRules, displayField);
    };
}

function intoUnsafeValidationRules<T>(validationRules: ValidationRule<T>[] | undefined, displayField?: string): UnsafeValidationRule[] | undefined {
    if (validationRules === undefined) {
        return undefined;
    }

    return validationRules.map(rule => {
        switch (rule.kind) {
            case "ruleset":
                const { kind, ...ruleset } = rule;
                return { displayField, ...ruleset };
            case "custom":
                return {
                    validator: (resolvedRule, value, callback) => {
                        const cb = once(callback);
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            cb(rule.validator(value, resolvedRule));
                        } catch (e: any) {
                            console.error(e);
                            cb(e.toString());
                        }
                    },
                    displayField,
                };
            case "async":
                return {
                    asyncValidator: (resolvedRule, value) => {
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            return rule.asyncValidator(value, resolvedRule);
                        } catch (e: any) {
                            console.error(e);
                            return Promise.reject(e.toString());
                        }
                    },
                    displayField,
                };
            default:
                return assertNever(rule);
        }
    });
}

interface GetFieldDecoratorOptions<T, ROOT> {
    valuePropName?: string,
    initialValue?: T,
    trigger?: string,
    getValueFromEvent?: (...args: any[]) => T | undefined,
    getValueProps?: (value: T | undefined) => { [k: string]: any },
    validateTrigger?: string | string[],
    rules?: UnsafeValidationRule[],
    exclusive?: boolean,
    normalize?: (value: T, prevValue: T, allValues: ROOT) => T,
    validateFirst?: boolean,
    preserve?: boolean,
}

interface WrappedFormUtils<DATA, ROOT> extends Antd.WrappedFormUtils<DATA> {
    getFieldsValue<ID extends keyof DATA & string>(fieldNames?: Array<ID>): Partial<DATA>,

    getFieldValue<ID extends keyof DATA & string>(fieldName: ID): DATA[ID] | undefined,

    setFieldsValue(obj: Partial<{ [K in keyof DATA]: DATA[K] }>, callback?: Function): void,

    setFields<ID extends keyof DATA & string>(obj: { [K in ID]: Field<DATA[K]> }, callback?: Function): void,

    /**
     * Can't override parent interface, so a cast in caller is needed...
     * @return ObjectError
     */
    getFieldError<ID extends keyof DATA & string>(name: ID): any,

    /**
     * Can't override parent interface, so a cast in caller is needed...
     * @return ObjectError
     */
    getFieldsError<ID extends keyof DATA & string>(names?: Array<ID>): any,

    isFieldValidating<ID extends keyof DATA & string>(name: ID): boolean,

    isFieldTouched<ID extends keyof DATA & string>(name: ID): boolean,

    isFieldsTouched<ID extends keyof DATA & string>(names?: Array<ID>): boolean,

    resetFields<ID extends keyof DATA & string>(names?: Array<ID>): void,

    getFieldDecorator<ID extends keyof DATA & string, T = DATA>(id: ID & keyof T, options?: GetFieldDecoratorOptions<DATA[ID], ROOT>): (node: React.ReactNode) => React.ReactNode,
}

interface FormComponentProps<ID extends string, T, R> extends Antd.FormComponentProps<{ [K in ID]: T }> {
    form: WrappedFormUtils<{ [K in ID]: T }, R>,
}

interface FormRootComponentProps<DATA> extends Antd.FormComponentProps<DATA> {
    form: WrappedFormUtils<DATA, DATA>,
}

interface FormCreateOption<DATA, TOwnProps, FIELDS> extends Antd.FormCreateOption<TOwnProps> {
    onFieldsChange?: (props: TOwnProps, fields: Partial<FIELDS>, allFields: FIELDS) => void,
    onValuesChange?: (props: TOwnProps, changedValues: Partial<DATA>, allValues: DATA) => void,
    mapPropsToFields?: (props: Omit<TOwnProps, "form">) => void,
}

function FormCreate<DATA, TOwnProps extends FormRootComponentProps<DATA>, FIELDS={ [K in keyof DATA]: Field<DATA[K]> }>(options?: FormCreateOption<DATA, TOwnProps, FIELDS>) {
    return AntdForm.create<TOwnProps>({
        validateMessages: localizedMessages(),
        ...options,
    });
}

// Default implementation of edit form
function EditFormCreate<T, Props extends EditProps<T | undefined> = EditProps<T | undefined>>() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    type DATA = { [K: string]: T };
    type TOwnProps = Props & EditFormProps<T> & FormRootComponentProps<DATA>;
    return FormCreate<DATA, TOwnProps>({
        onFieldsChange(props, fields) {
            const field = fields[props.id];
            if (!field) {
                return;
            }

            if (!field.validating && !field.errors) {
                const promise = props.onChange(field.value);
                if (promise) {
                    const { setPendingChanges, setChangeErrors } = props;
                    setPendingChanges(true);
                    promise.then(() => {
                        setPendingChanges(false);
                    }).catch(err => {
                        setChangeErrors([{ message: err.toString(), field: props.id }]);
                    });
                } else {
                    const { unlockValue } = props;
                    unlockValue();
                }
            } else {
                const { lockValue } = props;
                lockValue(field.value, field.validating, field.errors);
            }
        },
        mapPropsToFields(props) {
            const lock: ValueLock<T> = props.lock;
            const value: T | undefined = lock.type === "Locked" ? lock.value : props.value;
            return {
                [props.id]: AntdForm.createFormField({
                    value,
                    errors: props.changeErrors,
                    validating: props.pendingChanges || undefined,
                }),
            };
        },
    });
}

function localizedMessages() {
    return {
        default: (field: string) => t("Form_Error_Default", { field }), // 'Validation error on field %s',
        required: (field: string) => t("Form_Error_Required", { field }), // '%s is required',
        enum: (field: string, options: string) => t("Form_Error_Enum", { field, options }), // '%s must be one of %s',
        whitespace: (field: string) => t("Form_Error_Whitespace", { field }), // '%s cannot be empty',
        date: {
            format: (field: string, date: string, format: string) => t("Form_Error_Date_Format", {
                field,
                date,
                format,
            }), // '%s date %s is invalid for format %s',
            parse: (field: string, date: string) => t("Form_Error_Date_Parse", { field, date }), // '%s date could not be parsed, %s is invalid',
            invalid: (field: string, date: string) => t("Form_Error_Date_Invalid", { field, date }), // '%s date %s is invalid',
        },
        types: {
            string: (field: string, type: string) => t("Form_Error_Types_String", { field, type }), // '%s is not a %s',
            method: (field: string, type: string) => t("Form_Error_Types_Method", { field, type }), // '%s is not a %s (function)',
            array: (field: string, type: string) => t("Form_Error_Types_Array", { field, type }), // '%s is not an %s',
            object: (field: string, type: string) => t("Form_Error_Types_Object", { field, type }), // '%s is not an %s',
            number: (field: string, type: string) => t("Form_Error_Types_Number", { field, type }), // '%s is not a %s',
            date: (field: string, type: string) => t("Form_Error_Types_Date", { field, type }), // '%s is not a %s',
            boolean: (field: string, type: string) => t("Form_Error_Types_Boolean", { field, type }), // '%s is not a %s',
            integer: (field: string, type: string) => t("Form_Error_Types_Integer", { field, type }), // '%s is not an %s',
            float: (field: string, type: string) => t("Form_Error_Types_Float", { field, type }), // '%s is not a %s',
            regexp: (field: string, type: string) => t("Form_Error_Types_Regex", { field, type }), // '%s is not a valid %s',
            email: (field: string, type: string) => t("Form_Error_Types_Email", { field, type }), // '%s is not a valid %s',
            url: (field: string, type: string) => t("Form_Error_Types_Url", { field, type }), // '%s is not a valid %s',
            hex: (field: string, type: string) => t("Form_Error_Types_Hex", { field, type }), // '%s is not a valid %s',
        },
        string: {
            len: (field: string, len: number) => t("Form_Error_String_Len", { field, len }), // '%s must be exactly %s characters',
            min: (field: string, min: number) => t("Form_Error_String_Min", { field, min }), // '%s must be at least %s characters',
            max: (field: string, max: number) => t("Form_Error_String_Max", { field, max }), // '%s cannot be longer than %s characters',
            range: (field: string, min: number, max: number) => t("Form_Error_String_Range", {
                field,
                min,
                max,
            }), // '%s must be between %s and %s characters',
        },
        number: {
            len: (field: string, len: number) => t("Form_Error_Number_Len", { field, len }), // '%s must equal %s',
            min: (field: string, min: number) => t("Form_Error_Number_Min", { field, min }), // '%s cannot be less than %s',
            max: (field: string, max: number) => t("Form_Error_Number_Max", { field, max }), // '%s cannot be greater than %s',
            range: (field: string, min: number, max: number) => t("Form_Error_Number_Range", {
                field,
                min,
                max,
            }), // '%s must be between %s and %s',
        },
        array: {
            len: (field: string, len: number) => t("Form_Error_Array_Len", { field, len }), // '%s must be exactly %s in length',
            min: (field: string, min: number) => t("Form_Error_Array_Min", { field, min }), // '%s cannot be less than %s in length',
            max: (field: string, max: number) => t("Form_Error_Array_Max", { field, max }), // '%s cannot be greater than %s in length',
            range: (field: string, min: number, max: number) => t("Form_Error_Array_Range", {
                field,
                min,
                max,
            }), // '%s must be between %s and %s in length',
        },
        pattern: {
            mismatch: (field: string, value: string, pattern: string) => t("Form_Error_Pattern_Mismatch", {
                field,
                value,
                pattern,
            }), // '%s value %s does not match pattern %s',
        },
    };
}

function casterWithIdWrapper<T>() {
    return function <ID extends keyof DATA & string, DATA extends { [K in ID]: T }, V extends Partial<T>> (id: ID, form: WrappedFormUtils<DATA, unknown>, value: V): Record<ID, V> {
        return { [id]: value } as Record<ID, V>;
    };
}

function casterFieldWithIdWrapper<T>() {
    return function <ID extends keyof DATA & string, DATA extends { [K in ID]: T }, V extends Field<T>> (id: ID, form: WrappedFormUtils<DATA, unknown>, value: Field<Partial<T>>): Record<ID, V> {
        return { [id]: value } as Record<ID, V>;
    };
}

function castValue<ID extends keyof DATA & string, DATA extends { [K in ID]: T }, T>(id: ID, form: WrappedFormUtils<DATA, unknown>, value: T): any {
    return value;
}

function subForm<ID extends keyof DATA & string, DATA, ROOT>(id: ID, parent: WrappedFormUtils<DATA, ROOT>): WrappedFormUtils<DATA[ID], ROOT> {
    type D = DATA[ID];

    // Internal behaviour, do not rely on these out of this function.
    const path = <CID extends keyof D & string>(child: CID): ID => `${id}.${child}` as ID;

    return {
        getFieldsValue<CID extends keyof D & string>(fieldNames?: CID[]) {
            const parentFieldsValue = parent.getFieldsValue([id]);
            const fieldsValue: Partial<D> = parentFieldsValue[id] ?? {};

            if (fieldNames) {
                const filteredValues: Partial<D> = {};
                for (const fieldId of fieldNames) {
                    filteredValues[fieldId] = fieldsValue[fieldId];
                }
                return filteredValues;
            }

            return fieldsValue;
        },
        getFieldValue<CID extends keyof D & string>(fieldName: CID): D[CID] {
            return parent.getFieldValue(path(fieldName)) as unknown as D[CID];
        },
        setFieldsValue(obj: Partial<D>, callback?: Function): void {
            const parentFields: any = {};

            for (const cid in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, cid)) {
                    parentFields[path(cid)] = obj[cid];
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            parent.setFieldsValue(parentFields, callback);
        },
        setFields<CID extends keyof D & string>(obj: { [K in CID]: Field<D[K]> }, callback?: Function): void {
            const parentFields: any = {};

            for (const cid in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, cid)) {
                    parentFields[path(cid)] = obj[cid];
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            parent.setFields(parentFields, callback);
        },
        validateFields(fieldNames?: any, options?: any, callback?: any): void {
            if (fieldNames instanceof Array) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                parent.validateFields(fieldNames.map(id => path(id)), options, callback);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                parent.validateFields(fieldNames, options, callback);
            }
        },
        validateFieldsAndScroll(fieldNames?: any, options?: any, callback?: any): void {
            if (fieldNames instanceof Array) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                parent.validateFieldsAndScroll(fieldNames.map(id => path(id)), options, callback);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                parent.validateFieldsAndScroll(fieldNames, options, callback);
            }
        },
        getFieldError<CID extends keyof D & string>(name: CID): string[] | undefined {
            return parent.getFieldError(path(name));
        },
        getFieldsError<CID extends keyof D & string>(names?: CID[]): ObjectError {
            const errors = (parent.getFieldsError(names && names.map(name => path(name))) as ObjectError)[id];
            if (errors instanceof Array) {
                throw new Error(`Got an error in subform ${id}, but subobject was expected. Errors: \n${errors.join("\n")}`);
            }
            return errors !== undefined ? errors : {};
        },
        isFieldValidating<CID extends keyof D & string>(name: CID): boolean {
            return parent.isFieldValidating(path(name));
        },
        isFieldTouched<CID extends keyof D & string>(name: CID): boolean {
            return parent.isFieldTouched(path(name));
        },
        isFieldsTouched<CID extends keyof D & string>(names?: CID[]): boolean {
            return parent.isFieldsTouched(names && names.map(name => path(name)));
        },
        resetFields<CID extends keyof D & string>(names?: CID[]): void {
            return parent.resetFields(names && names.map(name => path(name)));
        },
        getFieldDecorator<CID extends keyof D & string, T = D>(cid: CID & keyof T, options?: GetFieldDecoratorOptions<D[CID], ROOT>): (node: React.ReactNode) => React.ReactNode {
            return parent.getFieldDecorator(path(cid), options as unknown as GetFieldDecoratorOptions<D, ROOT>);
        },
    };
}

interface Mappers<A, B, ROOT> {
    /**
     * Map a partial A object to the corresponding partial B object
     * @param a
     */
    partialAToB(a: Partial<A>): Partial<B>,

    /**
     * Map a partial B object to the corresponding partial A object
     * @param b
     */
    partialBtoA(b: Partial<B>): Partial<A>,

    /**
     * Map a key of B to a key of A
     * @param key
     */
    keyBtoA(key: keyof B & string): keyof A & string,

    /**
     * Map a value of A (A[keyBtoA(key)]) to it's B counterpart
     * @param value
     * @param key
     */
    valueAToB<KA extends keyof A & string, KB extends keyof B & string>(value: A[KA], key: KB): B[KB],

    /**
     * Map a A Field record to B Field record
     * @param fields
     */
    fieldsBtoA<KB extends keyof B & string, KA extends keyof A & string>(fields: { [K in KB]: Field<B[K]> }): { [K in KA]: Field<A[K]> },

    /**
     * Map B GetFieldDecoratorOptions to A counterpart
     * @param key
     * @param options
     */
    optionBtoA<KB extends keyof B & string, KA extends keyof A & string>(key: KB, options: GetFieldDecoratorOptions<B[KB], ROOT>): GetFieldDecoratorOptions<A[KA], ROOT>,
}

function defaultGetValueFromEvent(e: undefined | React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement> | string[]): unknown {
    if (!e || !(e as React.MouseEvent | React.ChangeEvent).target) {
        return e;
    }

    const { target } = e as React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>;
    return (target as HTMLInputElement | HTMLSelectElement).type === "checkbox" ?
        (target as HTMLInputElement).checked : (target as HTMLInputElement).value;
}

/**
 * Simple mapper implementation, map a single field id of type A to type B
 * @param id the field id in form
 * @param aToB transform a A value into B value
 * @param bToA transform a A value into B value
 */
function simpleMappers<ID extends string, A, B, ROOT>(
    id: ID,
    aToB: (a: A) => B,
    bToA: (b: B) => A,
) {
    // Helper function to cast A | unknown / B | unknown to the right type
    function is<T>(t: unknown, key: string): t is T {
        return key === id;
    }

    return function <RA extends Record<ID, A>, RB extends Record<ID, B>> (): Mappers<RA, RB, ROOT> {
        return {
            valueAToB: (a: unknown, key: keyof RB & string) => {
                if (is<A>(a, key)) {
                    return aToB(a);
                }
                return a as any;
            },
            fieldsBtoA: <KB extends keyof RB & string, KA extends keyof RA & string>(fields: { [K in KB]: Field<B> }) => {
                const fieldsA: { [K in KA]: Field<unknown> } = {} as any;
                const fieldsEntries = Object.entries<Field<unknown>>(fields);
                fieldsEntries.forEach(([key, field]) => {
                    if (is<Field<B>>(field, key)) {
                        const { value, ...rest } = field;
                        fieldsA[key as KA] = { ...rest, value: bToA(value) };
                    } else {
                        fieldsA[key as KA] = field;
                    }
                });
                return fieldsA as any;
            },
            keyBtoA: (key: ID): ID => key,
            optionBtoA: <KB extends keyof RB & string>(key: KB, options: GetFieldDecoratorOptions<RB[KB], ROOT>) => {
                if (is<GetFieldDecoratorOptions<B, ROOT>>(options, key)) {
                    const { initialValue, getValueFromEvent, normalize, getValueProps, ...rest } = options;
                    return {
                        ...rest,
                        initialValue: initialValue !== undefined ? bToA(initialValue) : undefined,
                        getValueFromEvent: (event: undefined | React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement> | string[]) => {
                            const parsedValue: B | undefined = getValueFromEvent ?
                                getValueFromEvent(event) :
                                defaultGetValueFromEvent(event) as (B | undefined);
                            return parsedValue !== undefined ? bToA(parsedValue) : undefined;
                        },
                        normalize: normalize && ((value: A, prevValue: A, root) => bToA(normalize(
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            aToB(value) as any,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            aToB(prevValue) as any,
                            root,
                        ))),
                        getValueProps: (value: A | undefined) => {
                            const b = value !== undefined ? aToB(value) : undefined;
                            if (getValueProps) {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                return getValueProps(b as any);
                            } else {
                                return { [options.valuePropName || "value"]: b };
                            }
                        },
                    };
                }
                return options as any;
            },
            partialAToB: fieldsA => {
                const fieldsB: any = {};
                Object.entries(fieldsA).forEach(([key, fA]) => {
                    if (key === id) {
                        fieldsB[key] = aToB(fA as A);
                    } else {
                        fieldsB[key] = fA as any;
                    }
                });
                return fieldsB;
            },
            partialBtoA: fieldsB => {
                const fieldsA: any = {};
                Object.entries(fieldsB).forEach(([key, fB]) => {
                    if (key === id) {
                        fieldsA[key] = bToA(fB as B);
                    } else {
                        fieldsA[key] = fB as any;
                    }
                });
                return fieldsA;
            },
        };
    };
}

/**
 * Transform a form of type A into one of type B, using provided mappers
 *
 * @see simpleMappers if want to simply map a field value
 */
function mapForm<B>() {
    return function <A, ROOT> (source: WrappedFormUtils<A, ROOT>, mappers: Mappers<A, B, ROOT>): WrappedFormUtils<B, ROOT> {
        return {
            getFieldsValue<KB extends keyof B & string>(fieldNames?: KB[]): Partial<B> {
                return mappers.partialAToB(source.getFieldsValue(fieldNames?.map(b => mappers.keyBtoA(b))));
            },
            getFieldValue<KB extends keyof B & string>(fieldName: KB): B[KB] | undefined {
                const aValue = source.getFieldValue(mappers.keyBtoA(fieldName));
                return aValue !== undefined ? mappers.valueAToB(aValue, fieldName) : undefined;
            },
            setFieldsValue(obj: Partial<B>, callback?: Function): void {
                source.setFieldsValue(mappers.partialBtoA(obj), callback);
            },
            setFields<KB extends keyof B & string>(obj: { [K in KB]: Field<B[K]> }): void {
                source.setFields(mappers.fieldsBtoA(obj));
            },
            validateFields(fieldNames?: any, options?: any, callback?: any): void {
                // Relies directly on parent implementation, maybe we should namespace the fieldNames, if supported
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                source.validateFields(fieldNames, options, callback);
            },
            validateFieldsAndScroll(fieldNames?: any, options?: any, callback?: any): void {
                // Relies directly on parent implementation, maybe we should namespace the fieldNames, if supported
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                source.validateFieldsAndScroll(fieldNames, options, callback);
            },
            getFieldError<KB extends keyof B & string>(name: KB): string[] | undefined {
                return source.getFieldError(mappers.keyBtoA(name));
            },
            getFieldsError<KB extends keyof B & string>(names?: KB[]): ObjectError {
                return source.getFieldsError(names?.map(b => mappers.keyBtoA(b)));
            },
            isFieldValidating<KB extends keyof B & string>(name: KB): boolean {
                return source.isFieldValidating(mappers.keyBtoA(name));
            },
            isFieldTouched<KB extends keyof B & string>(name: KB): boolean {
                return source.isFieldTouched(mappers.keyBtoA(name));
            },
            isFieldsTouched<KB extends keyof B & string>(names?: KB[]): boolean {
                return source.isFieldsTouched(names?.map(b => mappers.keyBtoA(b)));
            },
            resetFields<KB extends keyof B & string>(names?: KB[]): void {
                source.resetFields(names?.map(b => mappers.keyBtoA(b)));
            },
            getFieldDecorator<KB extends keyof B & string, T = B>(cid: KB & keyof T, options?: GetFieldDecoratorOptions<B[KB], ROOT>): (node: React.ReactNode) => React.ReactNode {
                return source.getFieldDecorator(mappers.keyBtoA(cid), options !== undefined ? mappers.optionBtoA(cid, options) : undefined);
            },
        };
    };
}

interface ObjectError {
    [id: string]: ObjectError | string[] | undefined,
}

function hasError(errors: ObjectError): boolean {
    for (const k in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, k)) {
            const error = errors[k];
            if (error !== undefined) {
                if (error instanceof Array || hasError(error)) {
                    return true;
                }
            }
        }
    }
    return false;
}

function pickFieldErrors(field: string, errors: ChangeError[] | undefined): ChangeError[] | undefined {
    if (errors === undefined) { return undefined; }
    const filter = (err: ChangeError) => {
        if (typeof err === "object" && err !== null && "field" in err) {
            return (err as any).field === field;
        }
        return true;
    };

    const remainingErrors = errors.filter(filter);
    return remainingErrors.length > 0 ? remainingErrors : undefined;
}

// Copied from the Rust backend, tested in the same way
const RFC_5321_EMAIL_REGEX = /^([-_a-zA-Z0-9!#\$%&'\*\+/=\?\^`{\|}~]+(\.[-_a-zA-Z0-9!#\$%&'\*\+/=\?\^`{\|}~]+)*|"([ !#-\[\]-~]|\\[ -~])*")@[a-zA-Z0-9](([-a-zA-Z0-9])*[a-zA-Z0-9])?(\.[a-zA-Z0-9](([-a-zA-Z0-9])*[a-zA-Z0-9])?)*$/;

function isEmailValid(email: string | undefined) {
    if (!email) {
        return false;
    }
    if (email.length >= 255) {
        return false;
    }
    return RFC_5321_EMAIL_REGEX.test(email);
}

function emailValidator(email: string | undefined) {
    if (isEmailValid(email)) {
        return undefined;
    } else {
        return t("Form_Error_Types_Email", { field: "Email", type: "email" });
    }
}
export type {
    FormComponentProps,
    FormRootComponentProps,
    WrappedFormUtils,
    ValidationRule,
    ObjectError,
    Field,
    CustomizedValidation,
};
export {
    FormCreate,
    EditFormCreate,
    validationRulesConverter,
    localizedMessages,
    casterWithIdWrapper,
    casterFieldWithIdWrapper,
    subForm,
    mapForm,
    hasError,
    pickFieldErrors,
    castValue,
    simpleMappers,
    intoUnsafeValidationRules,
    isEmailValid,
    emailValidator,
};
