import { t } from "utils/i18n";
import { canGoBack } from "utils/url";

interface SharedErrorProps {
    title: string,
    subtitle: string,
    action: {
        label: string,
        handler: () => void,
    },
}

export const getSharedErrorProps = (code: number|undefined): SharedErrorProps => {
    let props: SharedErrorProps;
    // Don't use router history because there may be no router at all.
    // See loggedIn[xxx]Error.tsx and anonymous[xxx]Error.tsx.
    const defaultAction = canGoBack(window.history) ? {
        label: t("ErrorPage_GoBack"),
        handler: () => history.back(),
    } : {
        label: t("ErrorPage_GoToDashboard"),
        handler: () => window.location.href = "/",
    };
    const title = `${getErrorPageTitle(code)} (${code})`;
    switch (code) {
        case 400: {
            props = {
                title,
                subtitle: t("ErrorPage_BadRequestText"),
                action: {
                    label: t("ErrorPage_Retry"),
                    handler: () => location.reload(),
                },
            };
            break;
        }
        case 403: {
            props = {
                title,
                subtitle: t("ErrorPage_ForbiddenText"),
                action: defaultAction,
            };
            break;
        }
        case 404: {
            props = {
                title,
                subtitle: t("ErrorPage_NotFoundText"),
                action: defaultAction,
            };
            break;
        }
        case 500: {
            props = {
                title,
                subtitle: t("ErrorPage_UnexpectedErrorText"),
                action: defaultAction,
            };
            break;
        }
        case 503: {
            props = {
                title,
                subtitle: t("ErrorPage_SomethingWentWrongText"),
                action: {
                    label: t("ErrorPage_ContactUs"),
                    handler: () => window.open("https://impero.com/get-in-touch/", "_blank"),
                },
            };
            break;
        }
        default: {
            props = {
                title,
                subtitle: t("ErrorPage_OtherText"),
                action: defaultAction,
            };
            break;
        }
    }
    return props;
};

export const getErrorPageTitle = (code: number|undefined): string => {
    switch (code) {
        case 400: {
            return t("ErrorPage_BadRequest");
        }
        case 403: {
            return t("ErrorPage_Forbidden");
        }
        case 404: {
            return t("ErrorPage_NotFound");
        }
        case 500: {
            return t("ErrorPage_UnexpectedError");
        }
        case 503: {
            return t("ErrorPage_SomethingWentWrong");
        }
        default: {
            return t("ErrorPage_Other");
        }
    }
};

