import * as React from "react";

import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Tooltip, Menu, Layout } from "antd";
import { ItemType } from "rc-menu/es/interface";
import { Link } from "react-router-dom";

import { DisabledState } from "componentTypes/disabled";
import { assertNever } from "utils/obj";


import theme from "./theme.module.scss";


class LeftSideMenu extends React.PureComponent<LeftSideMenuProps, LeftSideMenuState> {
    state = {
        collapsed: false,
    };

    render = () => {
        const { id, items, selectedItemId, children } = this.props;
        const { collapsed } = this.state;
        let openIds;
        const selectedBranch = this.findItemBranchById(selectedItemId);
        if (selectedBranch) {
            if (selectedBranch.length <= 1) {
                openIds = items.map(item => item.id);
            } else {
                openIds = selectedBranch.map(item => item.id);
            }
        }

        const iconProps = {
            className: theme.leftSideMenuToggle,
            onClick: () => this.setState({ collapsed: !this.state.collapsed }),
        };
        const LeftIcon = <CaretLeftOutlined {...iconProps} />;
        const RightIcon = <CaretRightOutlined {...iconProps} />;

        return (
            <Layout className={theme.leftSideMenuContainer}>
                <Layout.Sider
                    className={theme.leftSideMenuSider}
                    collapsible={true}
                    collapsedWidth={24}
                    trigger={null}
                    collapsed={collapsed}
                    width={250}
                >
                    <div className={theme.leftSideMenuToggleContainer}>
                        {collapsed ? RightIcon : LeftIcon}
                    </div>
                    { !collapsed ? <Menu
                        id={id}
                        mode="inline"
                        className={theme.leftSideMenu}
                        selectedKeys={[selectedItemId]}
                        defaultOpenKeys={openIds}
                        onClick={({ keyPath }) => {
                            const clickedItem = this.findItemByPath(keyPath);
                            if (clickedItem && clickedItem.type === "SimpleMenuItem" && clickedItem.handler.type === "CLICK") {
                                clickedItem.handler.onClick();
                            }
                        }}
                        items={items.map(item => this.mapItemToMenu(item, 0))}
                    /> : null }
                </Layout.Sider>
                <Layout.Content className={theme.content}>{ children }</Layout.Content>
            </Layout>
        );
    };

    private mapItemToMenu = (item: MenuItemSpec, depth: number): ItemType => {
        const className = depth <= 0 ? theme.menuItemFirstLevel : theme.menuItemDeeperLevel;
        switch (item.type) {
            case "SimpleMenuItem": {
                const { disabled } = item;
                let itemContent;
                switch (item.handler.type) {
                    case "HTML":
                        itemContent = <a className={theme.leftSideMenuLink} href={item.handler.href}>{item.label}</a>;
                        break;
                    case "CLICK":
                        itemContent = item.label;
                        break;
                    case "ROUTER":
                        itemContent = <Link className={theme.leftSideMenuLink} to={item.handler.linkTo}>{item.label}</Link>;
                        break;
                    default:
                        return assertNever(item.handler);
                }
                return {
                    key: item.id,
                    disabled: !!disabled,
                    className,
                    label: disabled && disabled.type === "DisabledWithReason"
                        ? <Tooltip placement="bottom" title={disabled.reason}>{itemContent}</Tooltip>
                        : itemContent,
                };
            }
            case "SubMenuItem": {
                return {
                    key: item.id,
                    className,
                    label: item.label,
                    children: item.childItems.map(item => this.mapItemToMenu(item, depth + 1)),
                };
            }
            default: {
                return assertNever(item);
            }
        }
    };

    private findItemBranchById = (id: string): (Array<MenuItemSpec> | undefined) => {
        return this.findItemBranchByIdInternal(id, this.props.items);
    };

    private findItemBranchByIdInternal = (id: string, items: Array<MenuItemSpec>): (Array<MenuItemSpec> | undefined) => {
        for (const item of items) {
            if (item.id === id) {
                return [item];
            } else if (item.type === "SubMenuItem") {
                const subBranch = this.findItemBranchByIdInternal(id, item.childItems);
                if (subBranch) {
                    return ([item] as Array<MenuItemSpec>).concat(subBranch);
                }
            }
        }
        return undefined;
    };

    private findItemByPath = (idPath: Array<string>): (MenuItemSpec | undefined) => {
        let { items } = this.props;
        let end = idPath.length;
        while (end > 0) {
            const id = idPath[end - 1];
            const item = items.find(item => item.id === id);
            if (!item) {
                console.error(`Could not find item ${id} in [${idPath.join(", ")}]`);
                return undefined;
            }
            if (end <= 1) {
                return item;
            }
            end -= 1;
            if (item.type === "SubMenuItem") {
                items = item.childItems;
            } else {
                console.error(`Expected sub menu item ${id}`);
                return undefined;
            }
        }
        console.error(`Could not find item [${idPath.join(", ")}]`);
        return undefined;
    };
}

interface LeftSideMenuProps {
    id: string,
    items: Array<MenuItemSpec>,
    selectedItemId: string,
    children: React.ReactNode,
    onItemSelected: (itemId: string) => void,
}

type LocationDescriptor = string | { pathname: string, search: string, hash: string, state: unknown };
type MenuItemSpec = SimpleMenuItemSpec | SubMenuItemSpec;
type SimpleMenuItemHandler = { type: "CLICK", onClick: () => void } | { type: "HTML", href: string } | { type: "ROUTER", linkTo: LocationDescriptor };

interface SimpleMenuItemSpec {
    type: "SimpleMenuItem",
    id: string,
    label: string,
    disabled?: DisabledState,
    handler: SimpleMenuItemHandler,
}

interface SubMenuItemSpec {
    type: "SubMenuItem",
    id: string,
    label: string,
    childItems: Array<MenuItemSpec>,
}

interface LeftSideMenuState {
    collapsed: boolean,
}

export {
    LeftSideMenu,
    type LeftSideMenuProps,
};
