import React, { useCallback, useEffect, useRef, useState } from "react";

import * as Sentry from "@sentry/react";
import { Tabs } from "antd";
import classNames from "classnames";

import theme from "./theme.module.scss";

const INITIAL_SCROLL_DELAY = 500;
const ANIMATION_FINISHED_MATCH_FRAMES = 5;

const smoothScroll = (elementId: string) => new Promise<void>(resolve => {
    const element = document.getElementById(elementId);

    if (!element) {
        const error = new Error("Expected element to be defined!");
        console.error(error);
        Sentry.captureException(error);
        return;
    }

    const scrollIntoView = () => element.scrollIntoView({ behavior: "smooth" });

    const checkAnimationFinished = (matchCount: number, prevRectTop: number | null) => {
        const rectTop = element.getBoundingClientRect().top;

        if (rectTop === prevRectTop) {
            if (matchCount + 1 > ANIMATION_FINISHED_MATCH_FRAMES) {
                resolve();
            } else {
                matchCount += 1;
            }
        } else {
            matchCount = 0;
            prevRectTop = rectTop;
        }

        requestAnimationFrame(() => checkAnimationFinished(matchCount, prevRectTop));
    };

    scrollIntoView();
    checkAnimationFinished(0, null);
});

export interface Anchor {
    elementId: string,
    label: string,
}
export interface AnchorsProps {
    anchors: Anchor[],
    contentRef: React.RefObject<HTMLDivElement>,
}
export const Anchors = ({
    anchors,
    contentRef,
}: AnchorsProps) => {
    const isAnimating = useRef<boolean>(false);

    const [currentAnchor, setCurrentAnchor] = useState<string | undefined>();
    // Ensures currentAnchor is initialized
    useEffect(() => {
        if (currentAnchor) {
            return;
        }
        const elementId = window.location.hash.substring(1);
        if (!elementId) {
            setCurrentAnchor(anchors[0].elementId);
            return;
        }
        isAnimating.current = true;
        setTimeout(() => {
            smoothScroll(elementId).then(() => {
                isAnimating.current = false;
            });
            setCurrentAnchor(elementId);
        }, INITIAL_SCROLL_DELAY);

    }, [anchors, currentAnchor]);


    const handleScroll = useCallback(() => {
        if (isAnimating.current) { // User triggered scroll to section - don't update
            return;
        }
        if (!currentAnchor) { // Not initialized - don't update
            return;
        }

        const anchorElements = anchors.map(anchor => document.getElementById(anchor.elementId));
        const container = contentRef.current;
        if (!container) {
            const error = new Error("Expected contentRef.current to be defined!");
            console.error(error);
            Sentry.captureException(error);
            return;
        }

        const elementsInView = anchorElements.filter(anchorElement => {
            if (!anchorElement) {
                const error = new Error("Expected anchorElement to be defined!");
                console.error(error);
                Sentry.captureException(error);
                return false;
            }
            const rect = anchorElement.getBoundingClientRect();

            const containerHeight = container.scrollHeight;
            const elementHeight = anchorElement.scrollHeight;

            const topInView = rect.top > 0;
            const heightExceedsContainer = elementHeight >= container.clientHeight;
            const bottomInView = (containerHeight - rect.bottom) > elementHeight;

            const elementInView = topInView && (heightExceedsContainer ? true : bottomInView);
            return elementInView;
        });
        const firstElementInView = elementsInView[0];
        if (!firstElementInView) {
            return;
        }
        setCurrentAnchor(firstElementInView.id);
    }, [anchors, contentRef, currentAnchor]);
    // Listens to contentRef scroll, updates currentAnchor based on elements in view
    useEffect(() => {
        const container = contentRef.current;
        if (!container) {
            const error = new Error("Expected contentRef.current to be defined!");
            console.error(error);
            Sentry.captureException(error);
            return;
        }
        container.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [contentRef, handleScroll]);

    const handleClick = (elementId: string) => {
        isAnimating.current = true;
        // replaceState replaces the current history entry - this ensures e.g. programmatic back navigation works as the user expects
        history.replaceState(null, "", `#${elementId}`);

        setCurrentAnchor(elementId);
        smoothScroll(elementId)
            .then(() => {
                isAnimating.current = false;
            });
    };


    const tabs = anchors.map(anchor => {
        const labelClasses = classNames(
            theme.anchor,
            anchor.elementId === currentAnchor && theme.anchorActive
        );
        return {
            label: <a className={labelClasses}>{anchor.label}</a>,
            key: anchor.elementId,
        };
    });

    return (
        <Tabs
            onChange={handleClick}
            activeKey={currentAnchor}
            items={tabs}
            className={theme.tabs}
        />
    );
};

