import * as Cookies from "es-cookie";
import { useCookies } from "react-cookie";

import type { GlobalUserProfile } from "types/global/user";

export type GlobalProfileCookie = GlobalUserProfile;
export const GLOBAL_PROFILE_COOKIE_NAME = "global_impero_profile";

export function getGlobalProfileCookie(): GlobalUserProfile | null {
    const cookie = Cookies.get(GLOBAL_PROFILE_COOKIE_NAME);
    if (!cookie) {
        return null;
    }
    const profile: GlobalUserProfile = JSON.parse(cookie);
    return profile;
}

type CookieKeys = typeof GLOBAL_PROFILE_COOKIE_NAME;
interface Cookies {
    [GLOBAL_PROFILE_COOKIE_NAME]: GlobalProfileCookie,
}
// Returns a stateful cookie value - an update to the cookie by the server will trigger a re-render
export const useGlobalProfileCookie = (): GlobalUserProfile | null => {
    const [cookies] = useCookies<CookieKeys, Cookies>([GLOBAL_PROFILE_COOKIE_NAME]);
    const globalProfileCookie = cookies[GLOBAL_PROFILE_COOKIE_NAME];
    if (!globalProfileCookie) {
        return null;
    }
    return globalProfileCookie;

};

