import * as React from "react";

import { Spin } from "antd";

import theme from "./theme.module.scss";

const PageSpinner = () => {
    return (
        <div className={theme.spinWrapper}><Spin size="large" /></div>
    );
};

export default PageSpinner;
