import * as React from "react";

import { Menu, Tooltip } from "antd";

import { ProtectedLink } from "components/protectedLink";
import { ActionSpec, ActionLink, ActionClick } from "componentTypes/action";
import More from "img/more.svg";
import { Svg } from "utils/svgIcon";

import theme from "./theme.module.scss";

class ActionMenu extends React.Component<ActionMenuProps> {
    render() {
        const { actions, id } = this.props;
        // Hack to allow usage of properties listed in https://github.com/react-component/menu#api and use an custom theme
        const extraProps = { triggerSubMenuAction: "click", theme: "impero-action-menu" } as {};
        const items = actions.map(this.renderAction);

        return <Menu id={id} mode="horizontal" className={theme.menu} selectable={false} {...extraProps}
            overflowedIndicator={<Svg className={theme.dots} src={More} />} items={items} />;
    }

    renderAction = (action: ActionSpec) => {
        const disabled = action.status !== undefined ? action.status.type === "DISABLED" : false;
        const label = action.status !== undefined && action.status.type === "DISABLED" ?
            <Tooltip title={action.status.reason}>
                <Svg className={theme.icon} src={action.icon.svg}/>
                {action.label}
            </Tooltip>
            : <>
                <Svg className={theme.icon} src={action.icon.svg}/>
                {action.label}
            </>;
        const actionLink: { href: string} | { to: string } | undefined = (action as ActionLink).link;
        const link = actionLink ? <ProtectedLink {...actionLink} hasAccess={true}>{label}</ProtectedLink> : label;
        const onClick: (() => void) | undefined = (action as ActionClick).onClick;

        return {
            key: action.id,
            className: `${theme.menuItem} ${disabled ? theme.disabledMenuItem : ""}`,
            onClick,
            disabled,
            label: link,
        };
    };
}

interface ActionMenuProps {
    id: string,
    actions: ActionSpec[],
}

export {
    ActionMenu,
    type ActionMenuProps,
};
