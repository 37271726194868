import { notification } from "antd";
import { AxiosResponse, AxiosError } from "axios";
import { atom, useAtomValue } from "jotai";

import { t } from "utils/i18n";

import { assertNever } from "./obj";

declare const process: {
    env: {
        NODE_ENV: string,
    },
};

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __AJAX_MANAGER_INSTANTIATED: boolean,
    }
}

interface StatefulComponent<S> {
    state: Readonly<S>,
    setState: <K extends keyof S>(partialState: Pick<S, K>) => void,
}

/**
 * One-stop for type safe ajax communication.
 *
 * Each app entry point must store an instance in their state.
 * Components must not create new instances and should require an instance via their props.
 */
class AjaxManager {

    constructor() {
        if (process.env.NODE_ENV === "development") {
            if (window.__AJAX_MANAGER_INSTANTIATED) {
                throw new Error("AjaxManager is already instanced. Please get instance from entry point App.");
            }
            window.__AJAX_MANAGER_INSTANTIATED = true;
        }

    }

    customAjax<T>(query: (() => Promise<AxiosResponse<T>>)): Promise<AxiosResponse<T>> {
        return query();
    }

    /**
     * Unsafe variant allowing to send multiple Axios queries
     * at the same time. Do not use this function directly, please use
     * safe variants such as ajax2 or ajax3.
     *
     * @param queries An array of typed Axios queries
     * @param params The AjaxMultiParams
     */
    ajaxMulti<
        ST extends { type: unknown },
        R extends unknown[]
    >(
        queries: (() => Promise<AxiosResponse<unknown>>)[],
        params: Readonly<AjaxMultiParameters<ST, R>>
    ): Promise<any[]> {
        const { component, inFlightStatus, initialStatusValue, expectedStatus } = params;
        if (component.state.status.type !== initialStatusValue) {
            return Promise.reject(`Expected status ${initialStatusValue}, got ${component.state.status.type}`);
        }
        component.setState({
            status: inFlightStatus,
        });
        return Promise.all(queries.map(q => q()))
            .then(resp => {
                if (expectedStatus !== undefined) {
                    expectedStatus.forEach((status: number, index: number) => {
                        if (status !== resp[index].status) {
                            throw new Error(`Expected status ${expectedStatus}, got ${resp[index].status}`);
                        }
                    });
                }
                const data = resp.map(r => r.data);
                const newState = params.getSuccessStatus(data as R);
                const appliedNewState = {
                    ...component.state.status,
                    ...newState,
                };
                component.setState({ status: appliedNewState });
                return Promise.resolve(data);
            })
            .catch((err: AxiosError) => {
                return this.rejectionHandler<R, ST>(err, params);
            });
    }

    /**
     * Single-query variant of the ajax call
     *
     * @param query The Axios query to be executed
     * @param params The parameters (AjaxMultiParameters) of this call
     */
    ajax<
        A,
        ST extends { type: unknown },
    >(
        query: (() => Promise<AxiosResponse<A>>),
        params: Readonly<AjaxMultiParameters<ST, [A]>>
    ): Promise<A> {
        return this.ajaxMulti<ST, [A]>([query], params)
            .then((responses: any[]) => {
                return Promise.resolve(responses[0] as A);
            })
            .catch((err: any) => {
                return Promise.reject(err);
            });
    }

    /**
     * Duo-query variant of the ajax call
     *
     * @param queryOne The first Axios query to be executed
     * @param queryTwo The second Axios query to be executed
     * @param params The parameters (AjaxMultiParameters) of this call
     */
    ajax2<
        A, B,
        ST extends { type: unknown },
    >(
        queryOne: (() => Promise<AxiosResponse<A>>),
        queryTwo: (() => Promise<AxiosResponse<B>>),
        params: Readonly<AjaxMultiParameters<ST, [A, B]>>
    ): Promise<[A, B]> {
        return this.ajaxMulti<ST, [A, B]>([queryOne, queryTwo], params)
            .then((responses: any[]) => {
                return Promise.resolve(responses as [A, B]);
            })
            .catch((err: any) => {
                return Promise.reject(err);
            });
    }

    /**
     * Trio-query variant of the ajax call
     *
     * @param queryOne The first Axios query to be executed
     * @param queryTwo The second Axios query to be executed
     * @param queryThree The third Axios query to be executed
     * @param params The parameters (AjaxMultiParameters) of this call
     */
    ajax3<
        A, B, C,
        ST extends { type: unknown },
    >(
        queryOne: (() => Promise<AxiosResponse<A>>),
        queryTwo: (() => Promise<AxiosResponse<B>>),
        queryThree: (() => Promise<AxiosResponse<C>>),
        params: Readonly<AjaxMultiParameters<ST, [A, B, C]>>
    ): Promise<[A, B, C]> {
        return this.ajaxMulti<ST, [A, B, C]>([queryOne, queryTwo, queryThree], params)
            .then((responses: any[]) => {
                return Promise.resolve(responses as [A, B, C]);
            })
            .catch((err: any) => {
                return Promise.reject(err);
            });
    }

    /**
     * Quartet-query variant of the ajax call
     *
     * @param queryOne The first Axios query to be executed
     * @param queryTwo The second Axios query to be executed
     * @param queryThree The third Axios query to be executed
     * @param queryFour The fourth Axios query to be executed
     * @param params The parameters (AjaxMultiParameters) of this call
     */
    ajax4<
        A, B, C, D,
        ST extends { type: unknown },
    >(
        queryOne: (() => Promise<AxiosResponse<A>>),
        queryTwo: (() => Promise<AxiosResponse<B>>),
        queryThree: (() => Promise<AxiosResponse<C>>),
        queryFour: (() => Promise<AxiosResponse<D>>),
        params: Readonly<AjaxMultiParameters<ST, [A, B, C, D]>>
    ): Promise<[A, B, C, D]> {
        return this.ajaxMulti<ST, [A, B, C, D]>([queryOne, queryTwo, queryThree, queryFour], params)
            .then((responses: any[]) => {
                return Promise.resolve(responses as [A, B, C, D]);
            })
            .catch((err: any) => {
                return Promise.reject(err);
            });
    }

    /**
     * Single-query variant of the ajax call. To be used in place of `ajax` when `query` returns
     * a result of type `A`, instead of type `AxiosResponse<A>`.
     *
     * @param query The Axios query to be executed
     * @param params The parameters (AjaxMultiParameters) of this call
     */
    ajaxSimple<
        A,
        ST extends { type: unknown }
    >(
        query: (() => Promise<A>),
        params: Readonly<AjaxMultiParameters<ST, A>>
    ): Promise<A> {
        const { component, inFlightStatus, initialStatusValue, errorHandlingPolicy } = params;
        if (component.state.status.type !== initialStatusValue) {
            return Promise.reject(`Expected status ${initialStatusValue}, got ${component.state.status.type}`);
        }
        component.setState({
            status: inFlightStatus,
        });
        return query()
            .then(resp => {
                const data = resp;
                const newState = params.getSuccessStatus(data);
                const appliedNewState = {
                    ...component.state.status,
                    ...newState,
                };
                component.setState({ status: appliedNewState });
                return Promise.resolve(data);
            })
            .catch((err: AxiosError) => {
                const policy: ErrorHandlingPolicy = errorHandlingPolicy ? errorHandlingPolicy : "NONE";
                switch (policy) {
                    case "NONE":
                        return Promise.reject(err);
                    case "DEFAULT":
                        return this.rejectionHandler<A, ST>(err, params);
                    default:
                        assertNever(policy);
                }
            });
    }

    private rejectionHandler<
        A,
        ST extends { type: unknown }
    >(
        err: AxiosError,
        params: Readonly<AjaxMultiParameters<ST, A>>
    ): Promise<A> {
        const { component, getErrorStatus } = params;
        component.setState({
            status: getErrorStatus(err.response && err.response.status),
        });
        let errorHandler = () => notification.error({
            message: t("Ajax_Error"),
            description: err.message,
        });
        if (err.response && params.onErrorCode) {
            const maybeErrorHandler = params.onErrorCode[err.response.status];
            if (maybeErrorHandler) {
                errorHandler = () => maybeErrorHandler(err);
            }
        }
        errorHandler();
        return Promise.reject(err);

    }

}

// This is to be used for passing ajaxManager to class-based components that still need it as a prop
const ajaxManagerAtom = atom(new AjaxManager());
const useAjaxManager = () => useAtomValue(ajaxManagerAtom);

interface WithStatus<ST> {
    status: ST,
}

type ErrorHandlingPolicy = "DEFAULT" | "NONE";

/**
 * Ajax query Parameters
 *
 * @field component the component to apply the modifications on. Usually `this`.
 * @field initialStatusValue the initial state that the component should be in when starting the query. This will be checked.
 * @field inFlightStatus the status of the component in which it will be while the Ajax query is sent
 * @field getErrorStatus computes the status of the component in which it will be if the Ajax query fails
 * @field getSuccessStatus computes the new status of the component with the results of the Ajax queries
 * @field expectedStatus (Optional) each query's result will be mapped to their return code and matched against the corresponding expected status.
 * @field onErrorCode When an error code happens on a query, the corresponding callback will be called
 */
interface AjaxMultiParameters<ST extends { type: unknown }, R> {
    component: StatefulComponent<WithStatus<ST>>,
    initialStatusValue: ST["type"],
    inFlightStatus: ST,
    getErrorStatus: (code: (number|undefined)) => ST,
    getSuccessStatus: (params: R) => ST,
    expectedStatus?: number[],
    onErrorCode?: { [code: number]: (error: AxiosError) => void },
    errorHandlingPolicy?: ErrorHandlingPolicy,
}
export type {
    AjaxMultiParameters,
    AxiosResponse,
    StatefulComponent,
    WithStatus,
    ErrorHandlingPolicy,
};
export {
    AjaxManager,
    useAjaxManager,
};
