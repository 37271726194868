import axios from "axios";

import { APIRoute, GlobalAPIRoute } from "routes";

function orgLogout() {
    axios.post(`${APIRoute.ROOT}/logout`)
        .then(res => {
            window.location.assign(res.headers["location"] as string);
        });
}

function globalLogout() {
    axios.post(`${GlobalAPIRoute.ROOT}/logout`)
        .then(res => {
            window.location.assign(res.headers["location"] as string);
        });
}

export {
    orgLogout,
    globalLogout,
};
