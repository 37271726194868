import * as React from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";

import { t } from "utils/i18n";

import { useOrgCookie } from "./orgCookie";

const withHelmetProvider = <P, >(WrappedComponent: React.ComponentType<P & JSX.IntrinsicAttributes>): React.ComponentType<P & JSX.IntrinsicAttributes> => {
    return (props: P & JSX.IntrinsicAttributes) => (
        <HelmetProvider>
            <WrappedComponent {...props} />
        </HelmetProvider>
    );
};

export const withHeader = <P, >(component: React.ComponentType<P & JSX.IntrinsicAttributes>): React.ComponentType<P & JSX.IntrinsicAttributes> =>
    withHelmetProvider(component);


interface HelmetTitleProps {
    titleLabel: string,
    children: React.ReactNode | React.ReactNode[] | undefined,
}
const HelmetTitle = ({
    titleLabel,
    children,
}: HelmetTitleProps) => {
    return (
        <Helmet>
            <title>{titleLabel}</title>
            {children}
        </Helmet>
    );
};

interface MetaProps {
    title: string | (() => string),
    children?: React.ReactNode | React.ReactNode[],
}
export const Meta = ({
    title,
    children,
}: MetaProps) => {
    const orgCookie = useOrgCookie();
    const titleThunk = title instanceof Function ? title : () => title;

    const titleLabel = orgCookie ?
        `Impero - ${titleThunk()} - ${orgCookie.name}` :
        `Impero - ${titleThunk()}`;
    return (
        <HelmetTitle titleLabel={titleLabel}>
            {children}
        </HelmetTitle>
    );
};


interface GlobalMetaProps {
    title: string | (() => string),
    children?: React.ReactNode | React.ReactNode[],
}
export const GlobalMeta = ({
    title,
    children,
}: GlobalMetaProps) => {
    const titleThunk = title instanceof Function ? title : () => title;

    return (
        <HelmetTitle titleLabel={`Impero - ${t("Administration_Title")} - ${titleThunk()}`}>
            {children}
        </HelmetTitle>
    );
};
