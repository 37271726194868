import * as React from "react";

import InlineSvg from "react-inlinesvg";

import remove from "../../img/remove.svg";
import { nullToEmpty } from "../../utils/string";


import theme from "./theme.module.scss";

interface DeleteButtonProps {
    className?: string,
    onClick?: () => void,
}

class DeleteButton extends React.Component<DeleteButtonProps> {
    render = (): JSX.Element => {
        const { className, onClick } = this.props;
        return (
            <span {...{ onClick }}>
                <InlineSvg
                    src={remove}
                    className={`${theme.deleteButton} ${nullToEmpty(
                        className
                    )}`}
                />
            </span>
        );
    };
}

export { DeleteButton };
export type { DeleteButtonProps };
