import * as React from "react";

import * as antd from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";
import type { ButtonType } from "antd/es/button";

class Button extends React.PureComponent<ButtonProps> {
    render() {
        return <antd.Button {...this.props} />;
    }
}

type ButtonProps = {
    id: string,
} & AntdButtonProps;

export { Button };
export type { ButtonType };
