import * as React from "react";

import classNames from "classnames";

import { FieldError } from "../../components/fieldError";

import theme from "./theme.module.scss";


/**
 * @field lite - if enabled, bottom margin will be 0. Meant to be used without a label, when the parent component already provides the spacing
 */
interface FormFieldProps {
    className?: string,
    lite?: boolean,
    fullWidth?: boolean,
    error?: string,
    children?: React.ReactNode,
}

/**
 * This component is a replacement for AntD Form.Item.
 * It should be used with new Jotai form system.
 */
const FormField = ({
    className,
    error,
    children,
    lite = false,
    fullWidth = false,
}: FormFieldProps) => {
    const rootClasses = classNames(
        className,
        lite ? theme.formItemLite : theme.formItem,
    );

    const formItemClasses = classNames(
        fullWidth ? theme.formItemFieldFullWidth :
            theme.formItemField
    );

    return (
        <div className={rootClasses}>
            <div className={formItemClasses}>
                {children}
            </div>
            <FieldError error={error} />
        </div>
    );

};

export { FormField };
export type { FormFieldProps };
