import React from "react";

import { Steps as AntdSteps } from "antd";
import classNames from "classnames";

import { TabSpec } from "componentTypes/tab";

import theme from "./theme.module.scss";


const { Step } = AntdSteps;

export interface StepsProps {
    stepSpecs: TabSpec[],
    currentStepId: string,
    limitedWidth?: boolean,
}
export const Steps = ({
    currentStepId,
    stepSpecs,
    limitedWidth = false,
}: StepsProps) => {
    const currentStepIndex = stepSpecs.findIndex(stepSpec => stepSpec.id === currentStepId);
    const rootClasses = classNames(theme.steps, limitedWidth && theme.limitedWidth);

    return (
        <AntdSteps className={rootClasses} current={currentStepIndex} size="small">
            {
                stepSpecs.map(stepSpec => (
                    <Step key={stepSpec.id} title={stepSpec.label} onClick={event => stepSpec.onClick({
                        key: stepSpec.id,
                        keyPath: [],
                        item: stepSpec,
                        domEvent: event,
                    })}/>
                ))
            }
        </AntdSteps>
    );
};
