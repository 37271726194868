import * as Cookies from "es-cookie";
import { useCookies } from "react-cookie";

export const ORG_COOKIE_NAME = "organization";

export function getMaybeOrgCookie(): OrgCookie | null {
    const orgCookieRaw = Cookies.get(ORG_COOKIE_NAME);

    if (orgCookieRaw === undefined) {
        return null;
    }

    if (orgCookieRaw.includes('"')) {
        // If the cookie contains double quotes, then it
        // means, that user still has the old version of the cookie
        // which was not URL encoded.
        // Therefore we just need to parse JSON.

        return JSON.parse(orgCookieRaw) as OrgCookie;
    }

    const decoded = decodeURIComponent(orgCookieRaw);

    return JSON.parse(decoded) as OrgCookie;
}

export function getOrgCookie(): OrgCookie {
    const orgCookie = getMaybeOrgCookie();
    if (orgCookie) {
        return orgCookie;
    } else {
        throw new Error("Cannot find organization cookie");
    }
}

export interface OrgCookie {
    name: string,
    shortName: string,
    loginRightSideLogo: string | null,
    loginRightSideBackground: string | null,
}

const mockOrgCookie: OrgCookie = {
    name: "test",
    shortName: "test",
    loginRightSideLogo: null,
    loginRightSideBackground: null,
};

type CookieKeys = typeof ORG_COOKIE_NAME;
interface Cookies {
    [ORG_COOKIE_NAME]?: string,
}
// Returns a stateful cookie value or null - an update to the cookie by the server will trigger a re-render
export const useOrgCookie = (): OrgCookie | null => {
    const [cookies] = useCookies<CookieKeys, Cookies>([ORG_COOKIE_NAME]);
    const orgCookie = cookies[ORG_COOKIE_NAME];
    if (!orgCookie && process.env.NODE_ENV === "test") {
        return mockOrgCookie;
    }
    if (!orgCookie) {
        return null;
    }

    // If the cookie contains double quotes, then it
    // means, that user still has the old version of the cookie
    // which was not URL encoded.
    // Therefore we just need to parse JSON.
    const isOldVersionCookie = orgCookie.includes('"');
    const orgCookieParsed: OrgCookie = JSON.parse(
        isOldVersionCookie ? orgCookie : decodeURIComponent(orgCookie)
    );

    return orgCookieParsed;
};
