import * as React from "react";

import Form from "@ant-design/compatible/es/form";

import theme from "./theme.module.scss";

class FormCard extends React.Component<FormCardProps> {
    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (this.props.onSubmitForm) { this.props.onSubmitForm(e); }
    };

    render() {
        const { children } = this.props;

        return (
            <Form onSubmit={this.handleSubmit} className={theme.formCard}>
                {children}
            </Form>
        );
    }
}

interface FormCardProps {
    children?: any,
    onSubmitForm?: (e: React.FormEvent) => void,
}

export default FormCard;
