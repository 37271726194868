import * as React from "react";

import theme from "./theme.module.scss";

interface FieldErrorProps {
    error: string | undefined,
}

const FieldError = ({ error }: FieldErrorProps): JSX.Element | null => {
    if (error === undefined) {
        return null;
    }

    return <p className={theme.error}>{error}</p>;
};

export { FieldError };
export type { FieldErrorProps };
