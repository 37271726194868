import * as React from "react";

import { Button, Popover } from "antd";

import { nullToEmpty } from "../../utils/string";

import styles from "./theme.module.scss";

interface DisabledLinkProps {
    /**
     * The component content.
     */
    children: React.ReactNode,
    /**
     * Any class name to add to the component.
     */
    className?: string,
    /**
     * An explanation for why the link is disabled.
     */
    reason: string,
}

class DisabledLink extends React.PureComponent<DisabledLinkProps> {
    render = (): JSX.Element => {
        const { children, reason, className } = this.props;
        return (
            <Popover content={reason}>
                <Button
                    type="link"
                    className={`${styles.disabledLinkButton} ${nullToEmpty(
                        className
                    )}`}
                >
                    {children}
                </Button>
            </Popover>
        );
    };
}

export { DisabledLink };
export type { DisabledLinkProps };
