import React from "react";

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import { BreadcrumbSpec } from "componentTypes/breadcrumb";
import { setFlashRedirectToCurrentLocation } from "utils/flash";
import { useGlobalProfileCookie } from "utils/globalProfileCookie";
import { GlobalMeta } from "utils/header";
import { t } from "utils/i18n";
import { globalLogout } from "utils/logout";

import { HeaderTitle } from "./headerTitle";
import theme from "./theme.module.scss";


const GlobalProfileSection = () => {
    const globalProfileCookie = useGlobalProfileCookie();
    const handleLogin = () => {
        setFlashRedirectToCurrentLocation();
        window.location.assign("/");
    };

    const nbsp = "\xA0";
    const orgName = t("Administration_Title");
    if (!globalProfileCookie) {
        return (
            <div className={theme.profile}>
                <div className={theme.profileIdentification}>
                    <span className={theme.organization}>{orgName}</span>
                    <Button id="login" type="link" className={theme.menuButton} onClick={handleLogin}>{t("Login_MenuTitle")}</Button>
                </div>
            </div>
        );
    }

    const menuItems = [{
        key: 0,
        label: <Button id="logout" type="link" className={theme.menuButton} onClick={globalLogout}>{t("Logoff_MenuTitle")}</Button>,
    }];
    const { email } = globalProfileCookie;
    const userName = email.replace(/ +/g, nbsp);
    return (
        <Dropdown menu={{ items: menuItems }} className={theme.profile} trigger={["click"]}>
            <div className={theme.profile}>
                <div className={theme.profileIdentification}>
                    <span className={theme.organization}>{orgName}</span>
                    <strong>{userName}</strong>
                </div>
                <DownOutlined type="down"/>
            </div>
        </Dropdown>
    );
};


export interface GlobalApplicationHeaderProps {
    title: string | BreadcrumbSpec,
    routerLinks?: boolean,
}
export const GlobalApplicationHeader = ({
    title,
}: GlobalApplicationHeaderProps) => {

    const isStringTitle = typeof title === "string";
    const pageTitle = isStringTitle ? title : title.title;

    return (
        <div className={theme.applicationHeader}>
            <GlobalMeta title={pageTitle} />
            <HeaderTitle title={title} />
            <GlobalProfileSection />
        </div>
    );
};


