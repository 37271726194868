import * as React from "react";

import theme from "./theme.module.scss";

interface CardFooterProps {
    className?: string,
    children?: React.ReactNode,
}

class CardFooter extends React.PureComponent<CardFooterProps> {
    render() {
        const { className, children } = this.props;

        const classNames = [
            Array.isArray(children) ? theme.cardFooter : theme.cardFooterCenter,
        ];
        if (className) {
            classNames.push(className);
        }

        return <div className={classNames.join(" ")}>{children}</div>;
    }
}

export { CardFooter };
export type { CardFooterProps };
