import * as React from "react";

import { Link } from "react-router-dom";

import { FormFrame } from "../../components/content";
import emptyFolder from "../../img/empty-folder.svg";
import { assertNever } from "../../utils/obj";
import { Svg } from "../../utils/svgIcon";


import theme from "./theme.module.scss";

export interface NoContentProps {
    header: string,
    description: string,
    actionCreation?:
    | { type: "HTML", label: string, href: string }
    | { type: "ROUTER", label: string, to: string },
}

export const NoContent = (props: NoContentProps) => {
    const { header, description, actionCreation } = props;
    let link;

    if (actionCreation) {
        switch (actionCreation.type) {
            case "ROUTER":
                link = (
                    <Link to={actionCreation.to}>{actionCreation.label}</Link>
                );
                break;
            case "HTML":
                link = <a href={actionCreation.href}>{actionCreation.label}</a>;
                break;
            default:
                assertNever(actionCreation);
        }
    }

    return (
        <FormFrame>
            <div className={theme.card}>
                <div>
                    <Svg src={emptyFolder} className={theme.icon} />
                </div>
                <br />
                <div className={theme.content}>
                    <div>
                        <b>{header}</b>
                    </div>
                    <div>{description}</div>
                </div>
                <br />
                {link}
            </div>
        </FormFrame>
    );
};
