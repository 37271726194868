import * as React from "react";

import theme from "./theme.module.scss";

const FormTitle = (props: FormTitleProps) => {
    const { title, styling } = props;
    return (
        <h1 style={styling} className={theme.cardTitle}>
            {title}
        </h1>
    );
};

interface FormTitleProps {
    title: string,
    styling?: Object,
}

export { FormTitle };
export type { FormTitleProps };
