import * as React from "react";

import { nullToEmpty } from "../../utils/string";

import theme from "./theme.module.scss";

interface ArrowProps {
    className?: string,
}

class Arrow extends React.PureComponent<ArrowProps> {
    render = (): JSX.Element => {
        const { className } = this.props;
        return <span className={`${theme.arrow} ${nullToEmpty(className)}`} />;
    };
}

export { Arrow };
