import * as React from "react";

import { Tooltip } from "antd";

import { renderMarkdown } from "../../components/markdown";
import informationCircle from "../../img/information-circle.svg";
import { assertNever } from "../../utils/obj";
import { Svg } from "../../utils/svgIcon";


import theme from "./theme.module.scss";

interface InfoTooltipProps {
    text: string,
    size?: "large" | "small",
}

const InfoTooltip = ({ text, size: maybeSize }: InfoTooltipProps) => {
    const size = maybeSize ?? "large";
    let iconClassName: string;
    switch (size) {
        case "large":
            iconClassName = theme.largeIcon;
            break;
        case "small":
            iconClassName = theme.smallIcon;
            break;
        default:
            return assertNever(size);
    }
    return (
        <Tooltip overlayClassName={theme.overlay} title={renderMarkdown(text)}>
            <span>
                <Svg src={informationCircle} className={iconClassName} />
            </span>
        </Tooltip>
    );
};

export { InfoTooltip };
export type { InfoTooltipProps };
