import { message } from "antd";

import { assertNever } from "utils/obj";

function emitNotificationOnQueryParam(paramName: string, paramValue: string, message: string, level: "error" | "warning" | "info" | "success") {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    if (params && params.get(paramName) && params.get(paramName)?.toLowerCase() === paramValue.toLowerCase()) {
        emitNotification(level, message);
        params.delete(paramName);
        if (window.history && window.history.pushState) {
            window.history.pushState({ path: url.toString() }, "", url.toString());
        }
    }
}


interface NotificationOptions {
    duration?: NotificationDuration,
}
type NotificationLevel = "error" | "warning" | "info" | "success";
type NotificationDuration = "NORMAL" | "DOUBLE";

function durationToSeconds(duration: NotificationDuration | undefined): number {
    switch (duration) {
        // 3 seconds is the default value from Antd
        case "NORMAL":
        case undefined:
            return 3;
        case "DOUBLE":
            return 6;
        default:
            return assertNever(duration);
    }
}

function emitNotification(
    level: NotificationLevel,
    msg: string,
    options: NotificationOptions = {},
) {
    const durationInSeconds = durationToSeconds(options.duration);
    switch (level) {
        case "error": message.error(msg, durationInSeconds); break;
        case "warning": message.warning(msg, durationInSeconds); break;
        case "info": message.info(msg, durationInSeconds); break;
        case "success": message.success(msg, durationInSeconds); break;
        default: assertNever(level);
    }
}

export {
    emitNotificationOnQueryParam,
    emitNotification,
};
