import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AxiosError } from "axios";

import { assertNever } from "./obj";

type AppLabel = "v2" | "legacy";

// Any error that contains one of the following in its description shall not get reported to Sentry
const ERROR_DESCRIPTION_DENYLIST = [
    "ResizeObserver loop completed with undelivered notifications.",
];
const ERROR_HTTP_STATUS_DENYLIST = [401, 403, 404];

// We need to remove some sensitive data like the password reset token from any collected data for sentry and pendo
// The password reset token is an uuid serialized to base64, which is what the first regex is looking for. Since the length of an uuid is always the same, we'll always have two == at the end as padding
// The external id is just an uuid inside the path to the collect screen which we also remove with the second regex
function sanitizeData<T>(data: T): T {
    let dat = JSON.stringify(data);
    dat = dat.replaceAll(/((?:[A-Za-z0-9\/+]|%2[BF]){22}(?:(?:%3D%3D)|(?:==)))/g, "<scrubbed_data>");
    dat = dat.replaceAll(/(\/Collect\/)[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g, "$1<scrubbed_data>");
    return JSON.parse(dat);
}

export const initializeSentry = (entrypointTag: string, appLabel: AppLabel): void => {

    const initialize = () => {

        Sentry.setTags({
            entrypoint: entrypointTag,
            app: appLabel,
        });
        Sentry.init({
            dsn: ENV_SENTRY_DSN,
            integrations: [new BrowserTracing()],
            environment: ENV_ENVIRONMENT,
            release: ENV_SENTRY_RELEASE_ID,
            tracesSampleRate: 0.1,
            beforeSend: (event, hint) => {
                event = sanitizeData(event);
                if (hint.originalException !== undefined && hint.originalException !== null) {
                    // Don't report errors that are 401 and 403
                    const axiosError = hint.originalException as AxiosError;
                    // isAxiosError can be undefined if the above cast has failed
                    if (axiosError.isAxiosError === true) {
                        if (axiosError.response !== undefined) {
                            const status = axiosError.response.status;
                            if (ERROR_HTTP_STATUS_DENYLIST.includes(status)) {
                                return null;
                            }
                        }
                    }
                }

                // Check the denylist of errors
                const exceptions = event.exception?.values;
                if (exceptions !== undefined) {
                    const exception = exceptions[0].value;
                    if (exception !== undefined) {
                        if (ERROR_DESCRIPTION_DENYLIST.find(deny => exception.includes(deny)) !== undefined) {
                            return null;
                        }
                    }
                }

                return event;
            },
            beforeSendTransaction: (event, _hint) => {
                return sanitizeData(event);
            },
            beforeBreadcrumb: (breadcrumb, _hint) => {
                return sanitizeData(breadcrumb);
            },
        });

    };
    switch (ENV_ENABLE_SENTRY_REPORTING) {
        case "false":
        case undefined:
            console.debug(`[${appLabel}/${entrypointTag}], Sentry reporting: off`);
            return;
        case "true":
            initialize();
            break;
        default:
            return assertNever(ENV_ENABLE_SENTRY_REPORTING);
    }
};
