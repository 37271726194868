import * as React from "react";

import remove from "../../img/remove.svg";
import * as formattingUtils from "../../utils/formatting";
import { nullToEmpty } from "../../utils/string";
import { Svg } from "../../utils/svgIcon";

import theme from "./theme.module.scss";

/**
 * Renders a control result metadata tag name as a `<span>`.
 *
 * @param {Object} props:
 * - `name` (string, required): the name of the tag.
 *
 * @return {Tag} a React node.
 */
function Tag(props: TagProps) {
    return <span className={theme.tagName}>{props.name}</span>;
}

interface TagProps {
    name: string,
}

/**
 * Renders a category and its tags as a `<div>`.
 *
 * @param {Object} props:
 * - `name` (string, required): the name of the category
 * - `tags` (array of strings, required): the list of tags
 */
function Category(props: CategoryProps) {
    const tagList = props.tags.map(tag => (
        <Tag key={"Category" + props.name + "_Tag" + tag} name={tag} />
    ));
    return (
        <div className={theme.tagGroup}>
            <span className={theme.categoryName}>{props.name}</span>
            <span>: </span>
            {formattingUtils.intersperse<React.ReactNode>(tagList, ", ")}
        </div>
    );
}

interface CategoryProps {
    name: string,
    tags: Array<string>,
}

interface BubbleTagProps {
    category: string,
    tag: string,
    formattedTag?: React.ReactNode,
    className?: string,
    categoryClassName?: string,
    tagClassName?: string,
    onDelete?: () => void,
}

class BubbleTag extends React.PureComponent<BubbleTagProps> {
    render = (): JSX.Element => {
        const {
            className,
            categoryClassName,
            tagClassName,
            category,
            tag,
            formattedTag,
            onDelete,
        } = this.props;
        return (
            <span
                className={`${theme.bubbleTagContainer} ${nullToEmpty(
                    className
                )}`}
                title={`${category}: ${tag}`}
            >
                <span className={theme.bubbleTagLabel}>
                    <span
                        className={`${theme.bubbleCategoryName} ${nullToEmpty(
                            categoryClassName
                        )}`}
                    >
                        {category}
                    </span>
                    <span
                        className={`${theme.bubbleTagName} ${nullToEmpty(
                            tagClassName
                        )}`}
                    >
                        {formattedTag || tag}
                    </span>
                </span>
                {onDelete ? (
                    <span className={theme.bubbleTagDelete}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                onDelete();
                            }}
                        >
                            <Svg className={theme.removeIcon} src={remove} />
                        </a>
                    </span>
                ) : null}
            </span>
        );
    };
}

function TagContainer(props: { children?: React.ReactNode }) {
    const { children } = props;
    return <div className={theme.tagContainer}>{children}</div>;
}

export { Category, Tag, BubbleTag, TagContainer };
export type { BubbleTagProps, CategoryProps };
