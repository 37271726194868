import * as React from "react";

import { nullToEmpty } from "../../utils/string";

import theme from "./theme.module.scss";

/**
 * Wrapper which sets the "borderedContainer" class on itself to instruct the content
 * to erase its external border. #AKindOfMagic
 */
class ContentWithoutBorder extends React.PureComponent<ContentWithoutBorderProps> {
    render = () => {
        const { id, className, children } = this.props;
        return (
            <div
                id={id}
                className={`borderedContainer ${nullToEmpty(className)}`}
            >
                {children}
            </div>
        );
    };
}

interface ContentWithoutBorderProps {
    id?: string,
    className?: string,
    children?: React.ReactNode,
}
interface FormFrameProps {
    id?: string,
    className?: string,
    children?: React.ReactNode,
}

class FormFrame extends React.PureComponent<FormFrameProps> {
    render = () => {
        const { id, className, children } = this.props;
        return (
            <div
                id={id}
                className={`${theme.formFrame} ${nullToEmpty(className)}`}
            >
                {children}
            </div>
        );
    };
}

export { ContentWithoutBorder, FormFrame };
