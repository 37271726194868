import * as React from "react";

import { ButtonType as AntdButtonType } from "antd/es/button";


import { Button } from "../../components/button";
import { IconSpec } from "../../componentTypes/icon";
import { Svg } from "../../utils/svgIcon";

import theme from "./theme.module.scss";

interface ButtonGroupProps {
    buttonSpecs: ButtonSpec[],
}

interface ButtonSpec {
    id: string,
    label: string,
    type: AntdButtonType,
    disabled?: boolean,
    danger?: boolean,
    loading?: boolean,
    ghost?: boolean,
    onClick: () => void,
    icon?: IconSpec,
}

class ButtonGroup extends React.PureComponent<ButtonGroupProps> {
    render = () => {
        const { buttonSpecs } = this.props;
        const length = buttonSpecs?.length ?? 0;

        if (length === 0) {
            return null;
        }

        return (
            <>
                {buttonSpecs.map(button => {
                    return (
                        <Button
                            key={button.id}
                            id={button.id}
                            type={button.type}
                            onClick={button.onClick}
                            disabled={button.disabled}
                            className={theme.buttonFooter}
                            danger={button.danger}
                            loading={button.loading}
                            ghost={button.ghost}
                        >
                            {button.icon
                                ? this.buildIconForButton(button.icon)
                                : null}
                            {button.label}
                        </Button>
                    );
                })}
            </>
        );
    };

    buildIconForButton = (icon: IconSpec) => {
        return <Svg className={theme.icon} src={icon.svg} />;
    };
}

export { ButtonGroup };
export type { ButtonSpec, ButtonGroupProps };
