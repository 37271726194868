import React, { useState } from "react";

import { Layout, Menu } from "antd";
import * as Cookies from "es-cookie";
import { Link } from "react-router-dom";

import { ApplicationBar } from "components/applicationBar/applicationBar";
const { Content, Sider } = Layout;
import ControlManagement from "img/control-management.svg";
import ControlTesting from "img/control-testing.svg";
import Globe from "img/globe.svg";
import Home from "img/home.svg";
import ImperoShare from "img/impero-share.svg";
import MenuIcon from "img/menu-icon.svg";
import Reports from "img/reports.svg";
import RiskModule from "img/risk-module.svg";
import TagsManagement from "img/tag-management-icon.svg";
import Users from "img/users.svg";
import { WebRoute } from "routes";
import { Meta } from "utils/header";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { t } from "utils/i18n";
import { useOrgUserProfileCookie } from "utils/profileCookie";
import { useSetLocizeCookieMutation } from "utils/queries/useSetLocizeCookieMutation";
import { Svg } from "utils/svgIcon";

import theme from "./theme.module.scss";

interface BodyProps {
    title: string,
    defaultAppBar?: boolean,
    routerLinks: boolean,
    children?: React.ReactNode,
}
export function Body({
    title,
    defaultAppBar,
    routerLinks,
    children,
}: BodyProps) {
    const orgProfileCookie = useOrgUserProfileCookie();
    const [collapsed, setCollapsed] = useState(true);
    const [narrowScreen, setNarrowScreen] = useState(false);

    const { mutateAsync: setLocizeCookie } = useSetLocizeCookieMutation();


    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const canLocize = orgProfileCookie ? orgProfileCookie.canLocalize : false;


    const toggleLocize = () => {
        const done = () => {
            window.location.reload();
        };
        if (Cookies.get("locize_api_key")) {
            const domain = window.location.hostname;
            const parentDomain = domain.replace(/^[^\.]+\./, "");
            Cookies.remove("locize_api_key");
            Cookies.remove("locize_api_key", { domain: "." + parentDomain, path: "/" });
            Cookies.remove("locize_api_key", { domain: parentDomain, path: "/" });
            done();
        } else {
            setLocizeCookie().then(done);
        }
    };

    const controlTestingEnabled = useFeatureFlag("controlTesting");
    const userLoggedIn = !!orgProfileCookie;

    const SmartLink = routerLinks ?
        ({ href, children }: { href: string, children: React.ReactNode }) => <Link to={href}>{children}</Link> :
        ({ href, children }: { href: string, children: React.ReactNode }) => <a href={href}>{children}</a>;

    const baseMenuItems = [
        {
            key: "dashboard",
            className: theme.LeftMenuItem,
            label: (
                <a href="/Dashboard">
                    <Svg className={theme.menuIcon} src={Home} />
                    <span className={theme.LeftMenuItemText}>{t("Dasboard_MenuTitle")}</span>
                </a>
            ),
        },
        {
            key: "controlManagement",
            className: theme.LeftMenuItem,
            label: (
                <SmartLink href="/controls">
                    <Svg className={theme.menuIcon} src={ControlManagement} />
                    <span className={theme.LeftMenuItemText}>{t("ControlManagement_ModuleTitle")}</span>
                </SmartLink>
            ),
        },
        {
            key: "controlTemplate",
            className: theme.LeftMenuItem,
            label: (
                <a href="/Share">
                    <Svg className={theme.menuIcon} src={ImperoShare} />
                    <span className={theme.LeftMenuItemText}>{t("ControlTemplate_MenuTitle")}</span>
                </a>
            ),
        },
        {
            key: "users",
            className: theme.LeftMenuItem,
            label: (
                <SmartLink href={WebRoute.USERS}>
                    <Svg className={theme.menuIcon} src={Users} />
                    <span className={theme.LeftMenuItemText}>{t("User_MenuTitle")}</span>
                </SmartLink>
            ),
        },
        {
            key: "report",
            className: theme.LeftMenuItem,
            label: (
                <a href="/Report">
                    <Svg className={theme.menuIcon} src={Reports} />
                    <span className={theme.LeftMenuItemText}>{t("Report_MenuTitle")}</span>
                </a>
            ),
        },
        {
            key: "risks",
            className: theme.LeftMenuItem,
            label: (
                <SmartLink href="/risks">
                    <Svg className={theme.menuIcon} src={RiskModule} />
                    <span className={theme.LeftMenuItemText}>{t("RiskModule_MenuTitle")}</span>
                </SmartLink>
            ),
        },
        {
            key: "tags",
            className: theme.LeftMenuItem,
            label: (
                <SmartLink href="/tags">
                    <Svg className={theme.menuIcon} src={TagsManagement} />
                    <span className={theme.LeftMenuItemText}>{t("TagManagement_MenuTitle")}</span>
                </SmartLink>
            ),
        },
        {
            key: "locize",
            className: theme.LeftMenuItem,
            onClick: toggleLocize,
            label: canLocize && (
                <>
                    <Svg className={theme.menuIcon} src={Globe} style={{ fill: Cookies.get("locize_api_key") ? "#2196F3" : "white" }} />
                    <span className={theme.LeftMenuItemText}>{"I18n"}</span>
                </>
            ),
        },
    ];
    const controlTestingMenuItem = {
        key: "controlTesting",
        className: theme.LeftMenuItem,
        label: (
            <SmartLink href={WebRoute.TESTING_PROGRAMS}>
                <Svg className={theme.menuIcon} src={ControlTesting} />
                <span className={theme.LeftMenuItemText}>{t("ControlTesting_ModuleTitle")}</span>
            </SmartLink>
        ),
    };
    const menuItems = controlTestingEnabled ? baseMenuItems.toSpliced(2, 0, controlTestingMenuItem) : baseMenuItems;

    return <>
        <Meta title={title} />
        <Layout className={theme.leftMenuContainer}>
            <Layout>
                {userLoggedIn ?
                    <>
                        {narrowScreen && collapsed ?
                            <div className={theme.menuTop}>
                                <div onClick={toggle} className={theme.menuTopButton}>
                                    <Svg className={theme.siderMenuIcon} src={MenuIcon} style={{ verticalAlign: "0.1em" }} />
                                </div>
                                <div className={theme.menuTopTitle}>
                                    {title}
                                </div>
                            </div>
                            : ""}
                        <Sider
                            className={theme.leftMenuSider}
                            collapsible
                            trigger={null}
                            collapsed={collapsed}
                            width="270px"
                            collapsedWidth={narrowScreen ? 0 : 70}
                            breakpoint="md"
                            onBreakpoint={setNarrowScreen}
                        >
                            <div onClick={toggle} className={theme.leftMenuTopIcon}>
                                <Svg className={theme.siderMenuIcon} src={MenuIcon} />
                                <span className={theme.MenuButtonText}>MENU</span>
                            </div>
                            <Menu theme="dark" mode="inline" className={theme.leftMenu}
                                items={menuItems}
                            />
                        </Sider>
                    </> : ""}
                <Layout>
                    <Content id="body-content" className={theme.contentContainer}>
                        {defaultAppBar ?
                            <ApplicationBar
                                title={title}
                                routerLinks={routerLinks}>{children}</ApplicationBar> :
                            children
                        }
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    </>;
}
