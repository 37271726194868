import { addHashToFlashCookie } from "./flash";

export function getDomainName() {
    return window.location.host.replace(/^[^.]+\./, "");
}

// the History interface used by react-router comes from history package,
// but adding a new deps feel unnecessary to me.
interface HistoryLite {
    length: number,
}

export function canGoBack(history: HistoryLite) {
    const params = new URLSearchParams(window.location.search);
    return !params.has("noBack") && history.length > 1;
}

// Don't use this function for the login flow because it does not
// push to history, see `loginRedirectToRoot` below.
export function forceReload() {
    window.location.reload();
}

export function loginRedirectToRoot() {
    // Rocket is not able to provide us with the hash of the request
    // at the time the flash cookie is set, so add it just before
    // Rocket is supposed to read the cookie.
    const hash = window.location.hash;
    if (hash && hash.length > 0) {
        addHashToFlashCookie(hash);
    }
    // Make the backend redirect to the flashed URL.
    // It is NOT a `forceReload` because otherwise a subsequent reload
    // will reload "/" without a flash cookie, thus redirect to the
    // dashboard.
    // It is NOT a `replace` for the same reason.
    window.location.assign("/");
}
