
import * as React from "react";

import checkCircleFilled from "../../img/check-circle.svg";
import { Svg } from "../../utils/svgIcon";


class CheckmarkIcon extends React.PureComponent<CheckmarkIconProps> {
    render = () => {
        const { checked } = this.props;

        if (!checked) {
            return null;
        }

        return (
            <Svg src={checkCircleFilled} />
        );
    };
}

interface CheckmarkIconProps {
    checked: boolean,
}

export {
    CheckmarkIcon,
};
export type {
    CheckmarkIconProps,
};
