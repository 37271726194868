import * as React from "react";

import Form from "@ant-design/compatible/es/form";
import type { FormItemProps as AntdFormItemProps } from "@ant-design/compatible/es/form/FormItem";
import classNames from "classnames";

import { ErrorValue, FormItemValidation, getErrorMessage } from "../../utils/errors";
import theme from "../formField/theme.module.scss";
// Note: We are reusing the same CSS as for FormField, because these two components are siblings
// The visual outcome should be indistinguishable from each other


/**
 * This is patched version of antd Form.Item.
 * For some reasons no version of the 1.x branch of `@ant-design/compatible` has `children`
 * in the FormItemProps, even though they do use `props.children` in this component.
 *
 * Webpack did not notice this particular issue, but Rollup does. So this tiny component
 * adds missing property
 */
type PatchedFormItemProps = AntdFormItemProps & { children?: React.ReactNode };
const PatchedAntdFormItem = (props: PatchedFormItemProps) => (
    <Form.Item {...props}/>
);

/**
 * @field lite - if enabled, bottom margin will be 0.
 */
interface FormItemProps {
    className?: string,
    lite?: boolean,
    hasFeedback?: boolean,
    validationError?: FormItemValidation,
    customError?: ErrorValue,
    children?: React.ReactNode,
}

/**
 * @deprecated This is the old way of rendering forms, that is using AntD `Form.Item`
 * Please use new Jotai-based Forms and replace this component with `FormField`
 *
 * Note: This component does not render label automatically. It is developers responsibility to include
 *   label as one of children of this component.
 */
const FormItem = ({ className, hasFeedback, validationError, children, customError, lite }: FormItemProps) => {
    const errorMessage = getErrorMessage(customError);

    const klass = classNames({
        "has-error": errorMessage,
        [theme.hasCustomError]: errorMessage,
        [theme.formItem]: !lite,
        [theme.formItemLite]: lite,
    }, className);

    return <PatchedAntdFormItem
        hasFeedback={hasFeedback}
        className={klass}
        {...validationError}
        data-errormessage={errorMessage}
    >
        <div className={theme.formItemField}>
            {children}
        </div>
    </PatchedAntdFormItem>;
};

export { FormItem };

export type { FormItemProps };
