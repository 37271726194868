import * as React from "react";

import { Remarkable } from "remarkable";
import { linkify } from "remarkable/linkify";
import RemarkableReactRenderer from "remarkable-react";

import { set } from "../../utils/obj";

// styling
import theme from "./theme.module.scss";

const IMPERO_CORE_RULES = ["block", "inline", "linkify"];

const IMPERO_BLOCK_RULES = ["paragraph", "list"];

const IMPERO_INLINE_RULES = ["links", "newline", "emphasis"];

/*
 * Creates a remarkable parser and renderer.
 *
 * The parameters allow to set specific preset/rules strictly (i.e. other rules are disabled).
 *
 * If no parameters are specified then the default IMPERO rules are used: links, newline and linkify mainly.
 */
function createMarkdownRenderer(
    preset?: string,
    coreRules?: Array<string>,
    blockRules?: Array<string>,
    inlineRules?: Array<string>
) {
    const md = new Remarkable(preset || "default").use(linkify);
    md.set({ breaks: true });

    md.core.ruler.enable(coreRules || IMPERO_CORE_RULES, true);
    md.block.ruler.enable(blockRules || IMPERO_BLOCK_RULES, true);
    md.inline.ruler.enable(inlineRules || IMPERO_INLINE_RULES, true);

    md.renderer = new RemarkableReactRenderer({
        components: {
            a: (props: React.HTMLProps<HTMLAnchorElement>) => {
                const aprops = {
                    key: props.key,
                    href: props.href,
                    title: props.title,
                    type: props.type,
                    target: "_blank",
                    children: props.children,
                };
                return <a {...aprops}>{props.children}</a>;
            },
        },
    });

    return md;
}

// Convenience function to render markdown with impero default rules
function renderMarkdown(
    str: string | null | undefined,
    env?: {}
): React.ReactNode {
    if (str === null || str === undefined || str === "") {
        return null;
    } else {
        const md = createMarkdownRenderer();
        try {
            return <div className={theme.markdown}>{md.render(str, env)}</div>;
        } catch (e) {
            const lines = str.split("\n");
            return <div className={theme.markdown}>{lines.map((line, index) => (<p key={index}>{line}</p>))}</div>;
        }
    }
}

function renderMarkdownWithRemarkableDefaults(
    str: string | null | undefined,
    env?: {}
): React.ReactNode {
    if (str === null || str === undefined || str === "") {
        return null;
    } else {
        const md = new Remarkable();
        md.renderer = new RemarkableReactRenderer();
        try {
            return <div className={theme.markdown}>{md.render(str, env)}</div>;
        } catch (e) {
            const lines = str.split("\n");
            return <div className={theme.markdown}>{lines.map((line, index) => (<p key={index}>{line}</p>))}</div>;
        }
    }
}

const DEMO_SOURCE =
    '![Impero Logo](/assets/img/logo_dark.png "Impero Logo")\n' +
    "\n" +
    "# **Impero** *Live* demo\n" +
    "\n" +
    "> Stay in\n" +
    "> **CONTROL**!\n" +
    "\n" +
    "Changes are automatically rendered as you type.\n" +
    "\n" +
    "## Table of Contents\n" +
    "\n" +
    "* Implements [GitHub Flavored Markdown](https://github.github.com/gfm/)\n" +
    '* Renders actual, "native" React DOM elements\n' +
    "* Allows you to escape or skip HTML (try toggling the checkboxes above)\n" +
    "* If you escape or skip the HTML, no `dangerouslySetInnerHTML` is used! Yay!\n" +
    "\n" +
    "## HTML block below\n" +
    "\n" +
    "<blockquote>\n" +
    "  This blockquote will change based on the HTML settings above.\n" +
    "</blockquote>\n" +
    "\n" +
    "## How about some code?\n" +
    "```js\n" +
    "var React = require('react');\n" +
    "var Markdown = require('react-markdown');\n" +
    "\n" +
    "React.render(\n" +
    '  <Markdown source="# Your markdown here" />,\n' +
    "  document.getElementById('content')\n" +
    ");\n" +
    "```\n" +
    "\n" +
    "Pretty neat, eh?\n" +
    "\n" +
    "## Tables?\n" +
    "\n" +
    "| Feature   | Support |\n" +
    "| --------- | ------- |\n" +
    "| tables    | ✔ |\n" +
    "| alignment | ✔ |\n" +
    "| wewt      | ✔ |\n" +
    "\n" +
    "## More info?\n" +
    "\n" +
    "Read usage information and more on [GitHub](//github.com/rexxars/react-markdown)\n" +
    "\n" +
    "---------------\n" +
    "\n" +
    "A component by [Espen Hovlandsdal](https://espen.codes/)\n";

interface PartialRemarkableConfig {
    components: { [name: string]: Remarkable.ComponentConfig },
}

const FullSetOfRulesConfig: PartialRemarkableConfig = {
    components: {
        core: {
            rules: [
                "block",
                "inline",
                "references",
                "replacements",
                "smartquotes",
                "abbr2",
                "footnote_tail",
            ],
        },
        block: {
            rules: [
                "blockquote",
                "code",
                "fences",
                "footnote",
                "heading",
                "hr",
                "htmlblock",
                "lheading",
                "list",
                "paragraph",
                "table",
            ],
        },
        inline: {
            rules: [
                "autolink",
                "backticks",
                "del",
                "emphasis",
                "entity",
                "escape",
                "footnote_ref",
                "htmltag",
                "links",
                "newline",
                "text",
            ],
        },
    },
};

class MarkdownDemo extends React.Component<{}, MarkdownDemoState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            source: DEMO_SOURCE,
            config: {
                components: {
                    core: {
                        rules: IMPERO_CORE_RULES,
                    },
                    block: {
                        rules: IMPERO_BLOCK_RULES,
                    },
                    inline: {
                        rules: IMPERO_INLINE_RULES,
                    },
                },
            },
        };
    }
    render() {
        const md = createMarkdownRenderer(
            "default",
            this.state.config.components["core"].rules,
            this.state.config.components["block"].rules,
            this.state.config.components["inline"].rules
        );
        return (
            <div className={theme.demo}>
                <div className={theme.editor}>
                    <textarea
                        className={theme.editorArea}
                        value={this.state.source}
                        onChange={e =>
                            this.setState({ source: e.target.value })
                        }
                    />
                </div>
                <div className={theme.display}>
                    <div className={theme.markdownBlock}>
                        {md.render(this.state.source)}
                    </div>
                </div>
                <div className={theme.options}>
                    <h4>core</h4>
                    {FullSetOfRulesConfig.components["core"].rules.map(
                        (rule: string) => {
                            return (
                                <div key={"core-" + rule}>
                                    <label className={theme.optionLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                this.state.config
                                                    .components["core"]
                                                    .rules.indexOf(
                                                        rule
                                                    ) >= 0
                                            }
                                            onChange={e =>
                                                this.changeRule(
                                                    "core",
                                                    rule,
                                                    e.target.checked
                                                )
                                            }
                                        />{" "}
                                        {rule}
                                    </label>
                                </div>
                            );
                        }
                    )}
                    <hr />
                    <h4>block</h4>
                    {FullSetOfRulesConfig.components["block"].rules.map(
                        (rule: string) => {
                            return (
                                <div key={"block-" + rule}>
                                    <label className={theme.optionLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                this.state.config
                                                    .components["block"]
                                                    .rules.indexOf(
                                                        rule
                                                    ) >= 0
                                            }
                                            onChange={e =>
                                                this.changeRule(
                                                    "block",
                                                    rule,
                                                    e.target.checked
                                                )
                                            }
                                        />{" "}
                                        {rule}
                                    </label>
                                </div>
                            );
                        }
                    )}
                    <hr />
                    <h4>inline</h4>
                    {FullSetOfRulesConfig.components["inline"].rules.map(
                        (rule: string) => {
                            return (
                                <div key={"inline-" + rule}>
                                    <label className={theme.optionLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                this.state.config
                                                    .components["inline"]
                                                    .rules.indexOf(
                                                        rule
                                                    ) >= 0
                                            }
                                            onChange={e =>
                                                this.changeRule(
                                                    "inline",
                                                    rule,
                                                    e.target.checked
                                                )
                                            }
                                        />{" "}
                                        {rule}
                                    </label>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }

    private changeRule(componentName: string, rule: string, enable: boolean) {
        const component = this.state.config.components[componentName];

        const newPartialComponents: {
            [comp: string]: Remarkable.ComponentConfig,
        } = {};
        newPartialComponents[componentName] = set(component, {
            rules: enable
                ? component.rules.concat([rule])
                : component.rules.filter(r => r !== rule),
        });
        const newConfig: PartialRemarkableConfig = set(this.state.config, {
            components: set(this.state.config.components, newPartialComponents),
        });
        this.setState({ config: newConfig });
    }
}

interface MarkdownDemoState {
    source: string,
    config: PartialRemarkableConfig,
}

export {
    IMPERO_CORE_RULES,
    IMPERO_BLOCK_RULES,
    IMPERO_INLINE_RULES,
    createMarkdownRenderer,
    renderMarkdown,
    renderMarkdownWithRemarkableDefaults,
    MarkdownDemo,
    DEMO_SOURCE,
};
