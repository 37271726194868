import * as React from "react";

import { Menu, Tooltip } from "antd";
import { MenuInfo } from "rc-menu/es/interface";

import { TabSpec } from "componentTypes/tab";
import { assertNever } from "utils/obj";
import { nullToEmpty } from "utils/string";

import theme from "./theme.module.scss";

class Tabs extends React.PureComponent<TabsProps> {

    render = () => {
        const { className, tabSpecs } = this.props;
        if (tabSpecs.length === 0) {
            return null;
        }

        const menuItems = tabSpecs.map(tab => {
            const divTab = <div id={tab.id}>
                {tab.label}
            </div>;
            let content: React.ReactNode;
            let disabledTab: boolean;
            const { disabled } = tab;
            if (disabled === undefined) {
                content = divTab;
                disabledTab = false;
            } else {
                switch (disabled.type) {
                    case "DisabledWithReason":
                        content = <Tooltip placement="bottom" title={disabled.reason}>{divTab}</Tooltip>;
                        disabledTab = true;
                        break;
                    case "Disabled":
                        content = divTab;
                        disabledTab = true;
                        break;
                    default:
                        return assertNever(disabled);
                }
            }

            return {
                className: theme.menuItem,
                key: tab.id,
                onClick: (event: MenuInfo) => tab.onClick({
                    domEvent: event.domEvent,
                    item: tab,
                    key: event.key,
                    keyPath: event.keyPath,
                }),
                disabled: disabledTab,
                label: content,
            };
        });

        return (
            <div className={`${theme.menu} ${nullToEmpty(className)}`}>
                <Menu
                    id={this.props.currentTabId}
                    selectedKeys={[this.props.currentTabId]}
                    mode="horizontal"
                    items={menuItems}
                />
            </div>
        );
    };
}

interface TabsProps {
    className?: string,
    tabSpecs: TabSpec[],
    currentTabId: string,
}

export {
    Tabs,
    type TabsProps,
};
