import * as React from "react";

import Mousetrap, { MousetrapInstance } from "mousetrap";

/**
 * A component to capture key strokes. If you don't want to capture key strokes
 * anymore, simply don't render the element, bound handlers will be removed.
 *
 * Props:
 * - `onGlobalKeyUp`: invoked when a keyUp event is registered anywhere
 * - `onGlobalKeyDown`: invoked when a keyDown event is registered anywhere
 * - `onGlobalKeyPress`: invoked when a keyPress event is registered anywhere
 * - `onLocalKeyUp`: invoked when a keyUp event is registered on the first child of the component
 * - `onLocalKeyDown`: invoked when a keyDown event is registered on the first child of the component
 * - `onLocalKeyPress`: invoked when a keyPress event is registered on the first child of the component
 */
class KeyTrap extends React.Component<KeyTrapProps> {
    private firstChild: HTMLElement | null;
    private localMousetrap: MousetrapInstance | null = null;
    constructor(props: KeyTrapProps) {
        super(props);
        this.firstChild = null;
    }
    // Don't use this.state anywhere as we don't need to trigger a re-render if
    // the listeners change
    componentWillMount() {
        this.addGlobalListeners();
    }
    componentWillUnmount() {
        this.removeGlobalListeners();
        this.removeLocalListeners();
        this.firstChild = null;
    }
    render() {
        const { className, children } = this.props;
        return (
            <div
                className={className}
                ref={div => {
                    if (div) {
                        this.updateFirstChild(div);
                    }
                }}
            >
                {children}
            </div>
        );
    }
    private updateFirstChild(div: HTMLElement) {
        if (!div || !div.firstChild) {
            this.firstChild = null;
            this.localMousetrap = null;
        } else if (this.firstChild !== div.firstChild) {
            this.removeLocalListeners();
            this.firstChild = div.firstChild as HTMLElement;
            this.addLocalListeners();
        }
    }
    private addGlobalListeners() {
        const { onGlobalKeyUp, onGlobalKeyDown, onGlobalKeyPress } = this.props;
        if (onGlobalKeyUp) {
            Object.keys(onGlobalKeyUp).forEach(key => {
                Mousetrap.bind(key, onGlobalKeyUp[key], "keyup");
            });
        }
        if (onGlobalKeyDown) {
            if (typeof onGlobalKeyDown !== "object") {
                return;
            }
            Object.keys(onGlobalKeyDown).forEach(key => {
                Mousetrap.bind(key, onGlobalKeyDown[key], "keydown");
            });
        }
        if (onGlobalKeyPress) {
            Object.keys(onGlobalKeyPress).forEach(key => {
                Mousetrap.bind(key, onGlobalKeyPress[key], "keypress");
            });
        }
    }
    private removeGlobalListeners() {
        const { onGlobalKeyUp, onGlobalKeyDown, onGlobalKeyPress } = this.props;
        if (onGlobalKeyUp) {
            Object.keys(onGlobalKeyUp).forEach(key => {
                Mousetrap.unbind(key, "keyup");
            });
        }
        if (onGlobalKeyDown) {
            Object.keys(onGlobalKeyDown).forEach(key => {
                Mousetrap.unbind(key, "keydown");
            });
        }
        if (onGlobalKeyPress) {
            Object.keys(onGlobalKeyPress).forEach(key => {
                Mousetrap.unbind(key, "keypress");
            });
        }
    }
    private addLocalListeners() {
        if (!this.firstChild) {
            return;
        }
        this.localMousetrap = Mousetrap(this.firstChild);
        const {
            unstable_localKeyHandle: unstableLocalKeyHandle,
            onLocalKeyUp,
            onLocalKeyDown,
            onLocalKeyPress,
        } = this.props;
        if (unstableLocalKeyHandle) {
            const oldHandle = (this.localMousetrap as any)._handleKey;
            (this.localMousetrap as any)._handleKey = (
                character: string,
                modifiers: Array<string>,
                e: KeyboardEvent
            ) => {
                if (unstableLocalKeyHandle(e)) {
                    return oldHandle.apply(this.localMousetrap, [
                        character,
                        modifiers,
                        e,
                    ]);
                }
            };
        }
        if (onLocalKeyUp) {
            Object.keys(onLocalKeyUp).forEach(key => {
                this.localMousetrap!.bind(key, onLocalKeyUp[key], "keyup");
            });
        }
        if (onLocalKeyDown) {
            Object.keys(onLocalKeyDown).forEach(key => {
                this.localMousetrap!.bind(key, onLocalKeyDown[key], "keydown");
            });
        }
        if (onLocalKeyPress) {
            Object.keys(onLocalKeyPress).forEach(key => {
                this.localMousetrap!.bind(
                    key,
                    onLocalKeyPress[key],
                    "keypress"
                );
            });
        }
    }
    private removeLocalListeners() {
        if (this.localMousetrap) {
            this.localMousetrap.reset();
            this.localMousetrap = null;
        }
    }
}

interface KeyTrapProps {
    className?: string,
    onGlobalKeyUp?: KeyToCallback,
    onGlobalKeyDown?: KeyToCallback,
    onGlobalKeyPress?: KeyToCallback,
    onLocalKeyUp?: KeyToCallback,
    onLocalKeyDown?: KeyToCallback,
    onLocalKeyPress?: KeyToCallback,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unstable_localKeyHandle?: (e: KeyboardEvent) => boolean,
    children?: React.ReactNode,
}

type KeyboardCallback = (e: KeyboardEvent, key: string) => boolean;

interface KeyToCallback {
    [key: string]: KeyboardCallback,
}

export { KeyTrap };
export type { KeyTrapProps };
