import * as Cookies from "es-cookie";

const FLASH_COOKIE = "_flash";
const FLASH_SUCCESS_MESSAGE = "success";

function getFlashCookie(): string | undefined {
    return Cookies.get(FLASH_COOKIE);
}

// Set a flash cookie for Rocket. Follows Rocket's implementation
function setFlashCookie(message: string, data: string) {
    const fiveMinutesFromNow = new Date();
    fiveMinutesFromNow.setTime(fiveMinutesFromNow.getTime() + 5 * 60 * 1000);
    // Emulate the behaviour of Rocket's flash cookie
    const content = `${message.length}:${message}${data}`;
    Cookies.set(FLASH_COOKIE, content, { expires: fiveMinutesFromNow });
}

function setFlashSuccessMessage(data: string) {
    setFlashCookie(FLASH_SUCCESS_MESSAGE, data);
}

function setFlashRedirectToCurrentLocation() {
    // Set a flash cookie to trigger a redirect to the current location after login
    const { pathname, search, hash } = window.location;
    const url = pathname + search + hash;
    setFlashSuccessMessage(url);
}

function addHashToFlashCookie(hash: string) {
    if (hash.charAt(0) !== "#") {
        console.error(`${hash} is not a hash`);
        return;
    }
    const flash = getFlashCookie();
    if (flash) {
        const flashUrl = (/^7:success(.*)$/).exec(flash);
        if (flashUrl) {
            const url = flashUrl[1];
            if (url.indexOf("#") === -1) {
                setFlashSuccessMessage(url + hash);
            }
        }
    }
}

export {
    getFlashCookie,
    setFlashSuccessMessage,
    setFlashRedirectToCurrentLocation,
    addHashToFlashCookie,
};
