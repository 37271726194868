import * as React from "react";

import { EditOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { InfoTooltip } from "../../components/infoTooltip";

import theme from "./theme.module.scss";

interface FormLabelProps {
    className?: string,
    showPencil?: boolean,
    helpText?: string,
    disabled?: boolean,
    onClick?: React.MouseEventHandler,
    children?: React.ReactNode,
}

const FormLabel = ({
    className,
    onClick,
    children,
    helpText,
    showPencil = false,
    disabled = false,
}: FormLabelProps) => {
    const pencilShown = !disabled && showPencil;

    const itemClasses = classNames(theme.label, className, {
        [theme.showPencil]: pencilShown,
    });

    const trailing = helpText && (
        <InfoTooltip text={helpText} size="small"/>
    );

    const handleClick = (event: React.MouseEvent) => {
        if (disabled) {
            return;
        }
        onClick?.(event);
    };

    const trailingInner = !helpText && pencilShown && (
        <EditOutlined className={theme.icon} onClick={handleClick}/>
    );


    return (
        <div className={theme.container}>
            { children &&
                <span className={itemClasses} onClick={handleClick} >
                    {children}
                    {trailingInner}
                </span>
            }
            {trailing}
        </div>
    );
};

export { FormLabel };
export type { FormLabelProps };
