import * as Cookies from "es-cookie";
import { useCookies } from "react-cookie";

import { UserProfile } from "types/user/user";

const ORG_PROFILE_COOKIE_NAME = "org_impero_profile";
export type OrgProfileCookie = UserProfile;

// Does not return a stateful value!
export function getOrgProfileCookie(): OrgProfileCookie | null {
    const cookies = Cookies.get(ORG_PROFILE_COOKIE_NAME);
    if (!cookies) {
        return null;
    }
    const profile: UserProfile = JSON.parse(cookies);
    return profile;
}

type CookieKeys = typeof ORG_PROFILE_COOKIE_NAME;
interface Cookies {
    [ORG_PROFILE_COOKIE_NAME]?: OrgProfileCookie,
}

// Returns a stateful cookie value - an update to the cookie by the server will trigger a re-render
export const useOrgUserProfileCookie = (): OrgProfileCookie | null => {
    const [cookies] = useCookies<CookieKeys, Cookies>([ORG_PROFILE_COOKIE_NAME]);
    return cookies[ORG_PROFILE_COOKIE_NAME] ?? null;
};

