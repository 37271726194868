import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { t } from "./i18n";
import { emitNotification } from "./notification";

// By default, let's disable all auto-fetching capabilities.
// Developers will enable those one by one if need be.
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retryOnMount: false,
            retry: false,
        },
        mutations: {
            onError: (error: Error | AxiosError) => {
                if (error instanceof AxiosError && error.isAxiosError && error.code) {
                    emitNotification(
                        "error",
                        t("Ajax_Client_HttpError_With_Code", { code: error.code }),
                    );
                } else {
                    emitNotification("error", t("Ajax_Client_Error"));
                }
            },
        },
    },
});
export const RETRY_COUNT = 3;

/**
 * The stale time for associated resources
 */
export const STALE_TIME_RESOURCES = 30 * 1000; // 30 seconds
