import * as React from "react";

import InlineSvg from "react-inlinesvg";

interface SvgProps {
    src: string,
    className?: string,
    style?: React.CSSProperties,
}

class Svg extends React.PureComponent<SvgProps> {
    render() {
        const { src, className, style } = this.props;
        return <InlineSvg src={src} className={className} style={style} />;
    }
}

export {
    Svg,
};
