function set<T, K extends keyof T>(obj: T, updatedValues: Pick<T, K> | undefined): T {
    return { ...obj, ...updatedValues };
}

function clone<T>(obj: T): T {
    return set(obj, {});
}

function assertNever(x: never): never {
    throw new Error("Unexpected object: " + JSON.stringify(x));
}

function undefinedToNull<T>(obj: T | undefined): T | null {
    if (obj === undefined) {
        return null;
    }
    return obj;
}

export {
    set,
    clone,
    assertNever,
    undefinedToNull,
};

