import React from "react";

import { RightOutlined } from "@ant-design/icons";
import { Typography, Breadcrumb } from "antd";
import { Link } from "react-router-dom";

import { BreadcrumbSpec } from "componentTypes/breadcrumb";
import Home from "img/home.svg";
import { Svg } from "utils/svgIcon";

import theme from "./theme.module.scss";

const { Text } = Typography;

interface BreadcrumbItemProps {
    label: string,
    url?: string,
    href?: string,
}
const BreadcrumbItem = ({
    label,
    url,
    href,
}: BreadcrumbItemProps) => {
    const breadcrumbLabel = (
        <Text ellipsis={{ tooltip: label }} className={theme.breadcrumbLink}>
            {label}
        </Text>
    );

    return (
        <Breadcrumb.Item separator={<RightOutlined type="right"/>}>
            {
                url ? <Link to={url}>{breadcrumbLabel}</Link>
                    : <a href={href}>{breadcrumbLabel}</a>
            }
        </Breadcrumb.Item>
    );
};

export interface HeaderTitleProps {
    title: string | BreadcrumbSpec,
}
export const HeaderTitle = ({
    title,
}: HeaderTitleProps) => {
    const isStringTitle = typeof title === "string";
    const pageTitle = isStringTitle ? title : title.title;

    return (
        <Breadcrumb separator={<RightOutlined type="right"/>} className={theme.breadcrumb}>
            <Breadcrumb.Item>
                <a href="/">
                    <Svg src={Home} className={theme.breadcrumbHome}/>
                </a>
            </Breadcrumb.Item>
            {!isStringTitle && title.breadCrumbs.map((breadcrumb, i) => (
                <BreadcrumbItem key={i} label={breadcrumb.label} url={breadcrumb.url} href={breadcrumb.href} />
            ))}

            <Breadcrumb.Item>
                <Text ellipsis={{ tooltip: pageTitle }} className={theme.breadcrumbLink}>
                    {pageTitle}
                </Text>
            </Breadcrumb.Item>

        </Breadcrumb>
    );
};
