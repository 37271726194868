import * as React from "react";

import theme from "./theme.module.scss";

const FormBox = (props: FormBoxProps) => {
    return <div className={theme.formBox}>{props.children}</div>;
};

interface FormBoxProps {
    children?: any,
}

/**
    Function components do not get along with some of our legacy components without the help of `forwardRef`.
    This `Compat` type brings a glue layer between two architectures, even though the `_ref` is not directly used.
**/
const FormBoxCompat = React.forwardRef((
    { children }: FormBoxProps,
    _ref
) => {
    return <FormBox>{children}</FormBox>;
});

export { FormBox, FormBoxCompat };
export type { FormBoxProps };
